//Хук для задержки запросов, нужен для например строки поиска
import { useCallback, useEffect } from "react";

/**
 * 
 * @param {Function} callee Обработчик запроса с задержкой
 * @param {Object} args Массив зависимостей
 * @param {number} delay Время задержки запроса 
 */
export default function useDebounce(callee, args = [], delay = 500) {

    const debounceFunc = useCallback(() => {
        callee();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...args])

    useEffect(() => {

        //Создаём таймоут
        const debounce = setTimeout(() => {
            debounceFunc();
        }, delay);

        //Ощищаем таймаут при размонтировании
        return () => {
            clearTimeout(debounce)
        }

    }, [debounceFunc, delay])

    return null;
}