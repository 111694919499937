import Textarea from 'react-expanding-textarea'
import defaultPhoto from '../img/defaultAvatar.png'
import { useState, Children, memo, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { getUUID } from '../global_function/function';
import useDebounce from '../custom_hooks/useDebounce';
import useCancelToken from '../custom_hooks/useCancelToken';


import _ from 'lodash';

//Тег, который расширивает текстареа
function ExpandingareaMemo(
    {
        value = '',
        style = {},
        isRequired = false,
        className = '',
        onClick = null,
        onFocus = null,
        onChange = null,
        onBlur = null,
        disabled = false,
        placeholder = ''
    }
) {

    const [isValidation, setIsValidation] = useState(false);

    //Функция проверки на валидацию только при изменении инпута
    function checkValidation() {
        if (!isValidation && isRequired) {
            setIsValidation(true);
        }
    }

    //Функция изменения, для проверки, что можно валидировать
    function handleChange(e) {
        checkValidation();
        //Дальше вызов функции из вне
        onChange?.(e);
    }

    //Для блюра также
    function handleBlur(e) {
        checkValidation();
        //Дальше вызов функции из вне
        onBlur?.(e);
    }

    return <Textarea
        defaultValue={value}
        style={style}
        className={`${isRequired ? "expanding-area validation-input" : "expanding-area"} ${className}`}
        onClick={onClick}
        onFocus={onFocus}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
        required={isValidation}
    />
}

export const Expandingarea = memo(ExpandingareaMemo, (prev, next) =>
    prev.value === next.value
    && prev.isRequired === next.isRequired
    && _.isEqual(_.omit(prev.onChange, _.functions(prev.onChange)), _.omit(next.onChange, _.functions(next.onChange)))
    && _.isEqual(_.omit(prev.onBlur, _.functions(prev.onBlur)), _.omit(next.onBlur, _.functions(next.onBlur)))
    && _.isEqual(_.omit(prev.onFocus, _.functions(prev.onFocus)), _.omit(next.onFocus, _.functions(next.onFocus)))
)

//Тег кастомных фото, которые обрабатывают ошибки
function FlluppsimageMemo({ styles = null, children = null, src = '', onClick = null, className = '', alt = '' }) {

    const [locSrc, setLocSrc] = useState(src);

    function handleError() {
        setLocSrc(defaultPhoto)
    }

    return (
        <div style={styles} onClick={onClick} className={`fllups-image ${className}`}>
            <img decoding='async' loading="lazy" src={locSrc} alt={alt} onError={handleError} />
            {children}
        </div>
    )
}

export const Flluppsimage = memo(FlluppsimageMemo, (prev, next) =>
    _.isEqual(prev.style, next.style)
    && prev.src === next.src
    && prev.className === next.className
    && _.isEqual(_.omit(prev.onClick, _.functions(prev.onClick)), _.omit(next.onClick, _.functions(next.onClick)))
)

//Тег ссылки на профиль
function profileLink(text = '', link = '', key = '') {
    return <Link target="_blank" rel="noopener noreferrer" className='profile-link' key={key} to={`/profile/${link.substring(1).replace(',', '')}`}>{text}</Link>
}

//Тег ссылки на ресурс
function urlLink(text = '', link = '', key = '') {
    return <a className='url-link' key={key} href={link} target='_blank' rel="noopener noreferrer">{text}</a>
}

//Тег текста со встроенными ссылками
function FlluppsLinkTextMemo({ children, style = {}, className = '' }) {

    //Функция, которая определяет ссылка это или текст, либо дом-элемент
    function getContent(element) {
        if (typeof (element) === 'object') {
            return element;
        };

        //Регулряка для ссылки на ресурс
        const urlPattern = /(^https:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
        //Регулярка для ссылки через @
        const mentionPattern = /^@[A-z_.,\-0-9]+$/g;

        if (typeof (element) === 'string') {

            //Массив слов через пробел
            const locValues = element.split(' ');

            //Результирующий массив элементов
            const resArr = [];

            //Теперь собирём нужную строку с символами
            let resRow = '';

            for (let i = 0; i < locValues.length; i++) {
                if ((!locValues[i].match(mentionPattern) && !locValues[i].match(urlPattern)) || (locValues[i].match(mentionPattern) && locValues[i].match(urlPattern))) {
                    resRow += locValues[i] + (i === locValues.length - 1 ? "" : " ");
                } else {
                    if (resRow.length) {
                        resArr.push(resRow);
                        //Очищаем
                        resRow = '';
                    }

                    if (locValues[i].match(mentionPattern)) {
                        resArr.push(profileLink(locValues[i] + " ", locValues[i], `text-${getUUID()}`))
                    }

                    if (locValues[i].match(urlPattern)) {
                        resArr.push(urlLink(locValues[i] + " ", locValues[i], `text-${getUUID()}`))
                    }
                }
            }

            if (resRow.length) {
                resArr.push(resRow);
                //Очищаем
                resRow = '';
            }

            return (
                <>
                    {
                        resArr.map((r, _) => {
                            return r;
                        })
                    }
                </>
            )
        }

        return null;
    }

    return (
        <p style={style} className={className}>
            {
                Children.count(children) > 1 ?
                    <>
                        {
                            children.map((ch, _) => {
                                return getContent(ch)
                            })
                        }
                    </>
                    :
                    <>
                        {getContent(children)}
                    </>
            }
        </p>
    )
}

export const FlluppsLinkText = memo(FlluppsLinkTextMemo);

//Тег поисковика с задержкой запроса
//ВАЖНО!!! В asyncFunc можно помещать хоть сколько параметров, но описываются они в верхнем уровне
//ПРИМЕР asyncFunc = {(search, cancelToken) => func(a, b, search, cancelToken)}
/**
 * 
 * @param {string} className Класс стилей 
 * @param {Function} asyncFunc Функция запроса
 * @param {string} placeholder обычный плейсхолдер
 * @param {Number} delay время задержки
 */
function FlluppsSearchInputMemo({ className = '', style = {}, asyncFunc = null, placeholder = 'поиск', delay = 500 }) {

    const [search, setSearch] = useState('');
    const [firstRender, setFirstRender] = useState(true);

    const { cancelToken } = useCancelToken([search]);

    //Функция ввода в поле
    function handleChange(e) {
        setSearch(e.target.value)
    }

    //UseEffect для определения, что это первый рендер
    useEffect(() => {
        setFirstRender(false);
    }, [])

    //Кастомный хук для задержкм
    useDebounce(() => {
        if (!firstRender) {
            asyncFunc(search, cancelToken);
        }
    }, [search], delay);

    return <input style={style} value={search} className={className} type='text' placeholder={placeholder} onChange={handleChange} />
}

export const FlluppsSearchInput = memo(FlluppsSearchInputMemo);

// Тег выпадающего списка элементов по поиску
/**
 * @param {string} boxClassName Класс стилей для блока(Позиционирование и размер, без высоты!!!)
 * @param {string} inputClassName Класс стилей для input(Все стили кроме позиционирования и широты!!!)
 * @param {string} placeholder Надпись на окне поиска
 * @param {string} dataSet Датасет для выбора (массив строк)
 * @param {Number} maxItems Максимальное число элементов к отображению
 * @param {any} value значение любого типа из списка
 * @param {Function} setValue установка значения
 */
function SelectSearchMemo({
    boxClassName = '',
    inputClassName = '',
    placeholder = '',
    dataSet = [],
    maxItems = 20,
    value = null,
    setValue = null
}) {

    const [search, setSearch] = useState(value ? `${value}` : ''); // Значение в окне поиска
    const [isOpened, setIsOpened] = useState(false); // Открытие списка

    // Обработчик ввода
    function handleChange(e) {
        if (e.target.value === '') {
            setValue(null);
        }
        setSearch(e.target.value);
    }

    // Обработчик нажатия на элемент списка
    function handleItemClick(e, value) {
        e.stopPropagation();
        setValue(value);
        setSearch(`${value}`);
        setIsOpened(false);
    }

    //Оброаботка нажатия для изменения цвета
    function handleTouchStart(e) {
        e.stopPropagation();
        e.target.style.backgroundColor = "#BABABA";
        e.target.style.color = "#7E52EE"
    }

    function handleTouchEnd(e) {
        e.stopPropagation();
        e.target.style.backgroundColor = "#7E52EE";
        e.target.style.color = "white";
    }

    return (
        <div className={`select-search-box ${boxClassName}`}>
            {/* в className div'а задать расположение и размер окна */}
            <input
                value={search}
                className={`select-search-box__input ${inputClassName}`}
                type="text"
                placeholder={placeholder}
                onChange={handleChange}
                onFocus={() => setIsOpened(true)}
                onBlur={() => setIsOpened(false)}

            />
            <ul className={isOpened ? 'select-search-box_list select-search-box_list--open' : 'select-search-box_list select-search-box_list--hidden'}>
                {dataSet
                    .filter((item, _) => item.toLowerCase().indexOf(search.toLowerCase()) > -1)
                    .slice(0, !!search ? maxItems : dataSet.length)
                    .map((item, idx) => {
                        return (
                            <li onClick={(e) => handleItemClick(e, item)} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd} onMouseDown={handleTouchStart} onMouseUp={handleTouchEnd} key={`list-id-${idx}`}>
                                {item}
                            </li>
                        )
                    })}
                {!dataSet.find((item) => item.toLowerCase().indexOf(search.toLowerCase()) > -1) && <p className="select-search__not-found-text">По вашему запросу ничего не найдено :(</p>}
            </ul>
        </div>
    );
}
export const SelectSearch = memo(SelectSearchMemo, (prev, next) => _.isEqual(prev.dataSet, next.dataSet) && prev.value === next.value);

//Тег для валидации инпута
function ValidationInputMemo({
    className = '',
    id = null,
    style = {},
    type = 'text',
    value = undefined,
    name = '',
    onChange = null,
    onBlur = null,
    onFocus = null,
    placeholder = null,
    pattern = null,
    disabled = false
}) {

    const [isValidation, setIsValidation] = useState(false);

    //Функция проверки на валидацию только при изменении инпута
    function checkValidation() {
        if (!isValidation) {
            setIsValidation(true);
        }
    }

    //Функция изменения, для проверки, что можно валидировать
    function handleChange(e) {
        checkValidation();
        //Дальше вызов функции из вне
        onChange?.(e);
    }

    //Для блюра также
    function handleBlur(e) {
        checkValidation();
        //Дальше вызов функции из вне
        onBlur?.(e);
    }

    return <input
        className={`validation-input ${className}`}
        type={type}
        style={style}
        autoComplete="off"
        id={id}
        name={name}
        disabled={disabled}
        value={value}
        placeholder={placeholder}
        pattern={pattern}
        required={isValidation}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={(e) => onFocus?.(e)}
    />
}
export const ValidationInput = memo(ValidationInputMemo, (prev, next) =>
    prev.value === next.value
    && prev.placeholder === next.placeholder
    && prev.name === next.name
    && prev.id === next.id
    && prev.type === next.type
    && prev.className === next.className
    && _.isEqual(prev.style, next.style)
    && prev.disabled === next.disabled
    && prev.pattern === next.pattern
    && _.isEqual(_.omit(prev.onChange, _.functions(prev.onChange)), _.omit(next.onChange, _.functions(next.onChange)))
    && _.isEqual(_.omit(prev.onBlur, _.functions(prev.onBlur)), _.omit(next.onBlur, _.functions(next.onBlur)))
    && _.isEqual(_.omit(prev.onFocus, _.functions(prev.onFocus)), _.omit(next.onFocus, _.functions(next.onFocus)))
);

//Скелетон для прогрузки данных
export function Skeleton({ className = '' }) {
    return <svg className={`skeleton-item ${className}`} />
}
