import { useEffect, createContext, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UpdateToken } from './service/routes/auth/auth';
import { GlobalStates } from './global_states/global_states';
import Modal from './global_components/modals/Modal';
import Router from './page_routes/Router';
import Footer from './global_components/footer/Footer';
import { FlluppsMainLoader } from './global_components/loaders/loaders';

import "./css/App.css";

export const mainContext = createContext();

function App() {

  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const [appLoading, setAppLoading] = useState(true);

  //Достаём работу с глобальными состояниями
  const globalStates = GlobalStates();
  const appvalue = globalStates.appvalue;
  const dispatch = globalStates.dispatch;

  /*Эффекты приложения*/

  useEffect(() => {
    appvalue.setFooterType(['/dialog', '/comments'].includes(location.pathname) ? 'text' : 'navigation');
    dispatch({ type: 'CHANGE_PAGE', payload: location.pathname === '/' ? 'home' : location.pathname.substring(1) });
  }, [location.pathname]);

  //Аутентификация пользователя
  useEffect(() => {
    UpdateToken()
      .then(d => {
        console.log('d - ', d)
      })
      .catch(_ => navigate('/login'))
      .finally(_ => setAppLoading(false));
  }, [])

  return (
    <>
      {
        appLoading ?
          (
            <FlluppsMainLoader />
          )
          :
          (
            <mainContext.Provider
              value={{
                ...appvalue,
                location,
                navigate,
                search,
                setSearch
              }}>
              <Modal />
              <Router />
              {
                !['/login'].includes(location.pathname) && (
                  <Footer />
                )
              }
            </mainContext.Provider>
          )
      }
    </>
  );
}

export default App;
