import { mainContext } from "../../../../App";
import { useContext } from "react";
import { Flluppsimage } from "../../../../custom_tags/custom_tags";

import FlluppsSvg from "../../../../fllupps_svg/FlluppsSvg";

export default function PreviewPartnersipCard(newsware) {

    //Разберём на необходимые элменты
    const {
        id = null,
        type = 'partnership',
        author_avatar = null,
        author_nickname = 'Bruh',
        name = "Предлагаю",
        want_to_find = null,
        geoposition = null,
        preview_image = null
    } = newsware;

    const { goToComments } = useContext(mainContext);

    return (
        <div onClick={() => goToComments(id, type)} className='partnership-box'>
            <Flluppsimage src={preview_image?.medium} alt="preview" className='partnership-box_image-box'>
                <svg className='partnership-box_image-box__favourites' name="icon-flag" xmlns="http://www.w3.org/2000/svg" width="14" height="22" viewBox="0 0 14 22" fill="none">
                    <path d="M2 17.1033L7 14.6437L12 17.1033V2.28807H2V17.1033ZM1.4 19.9062C1.06667 20.0588 0.75 20.0252 0.45 19.8056C0.15 19.5867 0 19.2675 0 18.848V2.28807C0 1.65885 0.196 1.12001 0.588 0.671549C0.979333 0.22385 1.45 0 2 0H12C12.55 0 13.021 0.22385 13.413 0.671549C13.8043 1.12001 14 1.65885 14 2.28807V18.848C14 19.2675 13.85 19.5867 13.55 19.8056C13.25 20.0252 12.9333 20.0588 12.6 19.9062L7 17.1605L1.4 19.9062ZM2 2.28807H12H7H2Z" name="icon-flag" fill="white"></path>
                    <path name="icon-flag" d="M2 17.1033L7 14.6437L12 17.1033V2.28807H7H2V17.1033Z" fill="none"></path>
                </svg>
                <div className='partnership-box_image-box_header-box'>
                    <Flluppsimage className="partnership-box_image-box_header-box_avatar-box" alt="avatar" src={author_avatar} />
                    <div className="partnership-box_image-box_header-box_author-box">
                        <p className="partnership-box_image-box_header-box_author-box__nickname">{author_nickname}</p>
                        <p className="partnership-box_image-box_header-box_author-box__geolocation">
                            <FlluppsSvg type="geoposition" style={{ marginRight: '2px' }} width={8} height={11} fill="none" />
                            {geoposition}
                        </p>
                    </div>
                </div>
            </Flluppsimage>
            <p className='partnership-box__name'>{name}</p>
            <p className='partnership-box__description'>{want_to_find}</p>
        </div>
    )

}