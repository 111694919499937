import { Conversion } from "../../../../global_function/function";
import { mainContext } from "../../../../App";
import { useContext } from "react";
import { Flluppsimage, FlluppsLinkText } from "../../../../custom_tags/custom_tags";
import FlluppsSvg from "../../../../fllupps_svg/FlluppsSvg";

//Карточка поста
export default function PostCard(newsware) {
    //Разберём на необходимые элменты
    const {
        id = null,
        type = 'post',
        full = false,
        author_avatar = null,
        author_nickname = 'Bruh',
        preview_text = 'Пост',
        preview_image = null,
        media = [],
        geoposition = null
    } = newsware;

    const { goToComments } = useContext(mainContext)

    const previewText = Conversion('previewSign', preview_text?.text);

    return (
        <div className="post-box">
            <div className={`post-box_header-box${full ? "--full" : ""}`}>
                <Flluppsimage className="post-box_header-box_avatar-box" src={author_avatar} alt="avatar" />
                <div className="post-box_header-box_author-box">
                    <p className="post-box_header-box_author-box__nickname">{author_nickname}</p>
                    <p className="post-box_header-box_author-box__geolocation">
                        <FlluppsSvg type="geoposition" style={{ marginRight: '2px' }} width={8} height={11} fill="none" />
                        {geoposition}
                    </p>
                </div>
            </div>
            {
                full ?
                    (
                        <>
                            {
                                media.map((m, _) => {
                                    if (m.type === 'text') {
                                        return <FlluppsLinkText key={`post-${m.uuid}`} className="post-box__text">{m.content?.text}</FlluppsLinkText>
                                    }
            
                                    if (m.type === 'image') {
                                        return <Flluppsimage key={`post-${m.uuid}`}  className="post-box_media-image-box" src={m.content?.medium} alt="media" />
                                    }
            
                                    return null;
                                })
                            }
                        </>
                    )
                    :
                    (
                        <>
                            <p className="post-box__text">
                                {previewText.sign}
                                {previewText.large && <span onClick={() => goToComments(id, type)} className="post-box__text--wrap">Развернуть</span>}
                            </p>
                            <Flluppsimage onClick={() => goToComments(id)} className="post-box_media-image-box" src={preview_image?.medium} alt="media" />
                        </>
                    )
            }
        </div>
    )
}