import React, { useEffect, useState, useContext } from 'react';
import logo_icon from '../../img/widenLogo.svg';
import { Auth } from '../../service/routes/auth/auth.js';
import { BtnLoader } from '../../global_components/loaders/loaders.js';
import { Flluppsimage } from '../../custom_tags/custom_tags.js';

import { mainContext } from '../../App.js';

import {
  SubmitBtn,
  AuthInput,
  PswdInput,
  CodeInput,
  LoginLink,
  ChooseBtn
} from './components/components.js';
import FlluppsSvg from '../../fllupps_svg/FlluppsSvg.jsx';

const Authorisation = () => {
  const { navigate, setUserProfile } = useContext(mainContext);

  //Loader
  const [loading, setLoading] = useState(false);

  //Функция, которая меняет определённых состояний
  function handleSwitchState(states) {
    setLoginStates(l => {
      const tmp = { ...l };
      return {
        ...tmp,
        ...states,
      }
    });
  }

  //Функция перехода между формами
  function handleSwitchForm(newActiveBox) {
    handleSwitchState({ activeBox: newActiveBox });
  }

  //Функция перехода с очищением
  function handleClearSwitchForm(newActiveBox) {
    setLoginStates({
      activeBox: newActiveBox,
      userCategory: '',
      interests: [],
      login: '',
      password: '',
      secondpassword: '',
      email: '',
      code: ['', '', '', ''],
      error: false,
    });
  }
  //Функция вводя в поля авторизации
  const handleChange = (e) => {
    handleSwitchState({ [e.target.name]: e.target.value });
  };

  //Функция ввода кода
  function handleChangeCode(e) {
    e.target.value = e.target.value ? (e.target.value %= 10) : '';
    const index = +e.target.name.split('-')[1];
    loginStates.code[index] = e.target.value;
    index < 3
      ? document.querySelector(`[name="code-${index + 1}"]`).focus()
      : document.querySelector(`[name="code-${0}"]`).focus();
  }

  //Функция фокуса на поле
  const handleFocus = () => {
    handleSwitchState({ error: false });
  };

  //Функция выбора категории
  function chooseCategory(e) {
    e.preventDefault();
    e.stopPropagation();
    handleSwitchState({ userCategory: e.target.name, error: false });
  }

  //Функция преобразования секунд в формат таймера
  function currentTime() {
    const minutes = Math.floor(timespan / 60);
    const seconds = Math.floor(timespan % 60);
    return seconds > 9 ? `${minutes}:${seconds}` : `${minutes}:0${seconds}`;
  }

  //Состояния Страницы Входа
  const [loginStates, setLoginStates] = React.useState({
    activeBox: 'login',
    userCategory: '',
    interests: [],
    login: '',
    password: '',
    secondpassword: '',
    email: '',
    code: ['', '', '', ''],
    error: false,
  });

  //Стейт таймера
  const [timespan, setTimeSpan] = useState(90);

  //Эффект для анимации
  useEffect(() => {
    setTimeSpan(90);

    handleSwitchState({ error: '' });

    const loginContainer = document.getElementById('login_container');

    loginContainer.classList.add('main');

    setTimeout(() => {
      loginContainer.classList.remove('main');
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginStates.activeBox]);

  //Таймер
  useEffect(() => {
    if (['reccode', 'regcode'].includes(loginStates.activeBox)) {
      if (timespan > 0) {
        setTimeout(() => {
          setTimeSpan(timespan - 1);
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timespan]);

  function handleBack() {
    switch (loginStates.activeBox) {
      case 'regcode':
        handleSwitchForm('regmail');
        break;
      case 'regpswd':
        handleSwitchForm('regmail');
        break;
      case 'regnick':
        handleSwitchForm('regpswd');
        break;
      case 'regcategory':
        handleSwitchForm('regnick');
        break;
      case 'recmail':
        handleClearSwitchForm('login');
        break;
      case 'reccode':
        handleSwitchForm('recmail');
        break;
      case 'recpswd':
        handleSwitchForm('recmail');
        break;
      default:
        handleClearSwitchForm('login');
        break;
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    switch (true) {
      case loginStates.activeBox === 'login':
        if (!loginStates.login.length || !loginStates.password) {
          handleSwitchState({ error: 'Логин или пароль не заполнены.' });
          break;
        }
        await Auth({ login: loginStates.login, password: loginStates.password })
          .then((d) => {
            setUserProfile(d.username, d.profile_photo)
            navigate('/home');
          })
          .catch((e) => handleSwitchState({ error: e.message }));
        break;
      default:
        return;
    }
    setLoading(false);
  }

  return (
    <>
      <div id="login_container" className="authorisation-main-container">
        <Flluppsimage src={logo_icon} alt='logo' className='authorisation-main-container__logo' />
        {!['login', 'regmail'].includes(loginStates.activeBox) && (
          <div onClick={handleBack} className="authorisation-main-container_back-login-box">
            <FlluppsSvg type='back_arrow' width={21} height={15} fill='none'/>
          </div>
        )}
        <form className="authorisation-main-container_form-box" onSubmit={handleSubmit}>
          {loginStates.activeBox === 'login' && (
            <>
              <h1 className="authorisation-main-container_form-box__caption">Вход</h1>
              <AuthInput
                value={loginStates.login}
                onFocus={handleFocus}
                onChange={handleChange}
                name="login"
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__text-input error-login'
                    : 'authorisation-main-container_form-box__text-input'
                }
                placeholder="Логин*"></AuthInput>
              <PswdInput
                value={loginStates.password}
                onFocus={handleFocus}
                onChange={handleChange}
                name="password"
                placeholder="Пароль*"
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__password-input error-login'
                    : 'authorisation-main-container_form-box__password-input'
                }></PswdInput>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Войти</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : 'none-active'}>
                {loginStates.error}
              </p>
              <p
                onClick={() => handleClearSwitchForm('recmail')}
                className="authorisation-main-container_form-box__forgot-pswd-link">
                Забыли пароль?
              </p>
            </>
          )}
          {loginStates.activeBox === 'regmail' && (
            <>
              <h1 className="authorisation-main-container_form-box__caption">Регистрация</h1>
              <p className="authorisation-main-container_form-box__hint">
                Укажите, пожалуйста, адрес эл. почты
              </p>
              <AuthInput
                value={loginStates.email}
                onFocus={handleFocus}
                onChange={handleChange}
                name="email"
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__text-input error-login'
                    : 'authorisation-main-container_form-box__text-input'
                }
                placeholder="Эл.Почта*"></AuthInput>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Продолжить</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : 'none-active'}>
                {loginStates.error}
              </p>
            </>
          )}
          {loginStates.activeBox === 'regcode' && (
            <>
              <p className="authorisation-main-container_form-box__caption">
                Введите, пожалуйста, код подтверждения
              </p>
              <p className="authorisation-main-container_form-box__hint">
                Мы отправили код подтверждения на почту: {loginStates.email}
              </p>
              <CodeInput
                onChange={handleChangeCode}
                onFocus={handleFocus}
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__code-input error-login'
                    : 'authorisation-main-container_form-box__code-input'
                }></CodeInput>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Продолжить</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : 'none-active'}>
                {loginStates.error}
              </p>
              {timespan === 0 ? (
                <p
                  onClick={() => alert('Отправил')}
                  className="authorisation-main-container_form-box__forgot-pswd-link">
                  Отправить код повторно
                </p>
              ) : (
                <p className="authorisation-main-container_form-box__hint">
                  Повторная отправка кода через<br></br>
                  {currentTime()}
                </p>
              )}
            </>
          )}
          {loginStates.activeBox === 'regpswd' && (
            <>
              <p className="authorisation-main-container_form-box__caption">
                Придумайте, пожалуйста, новый пароль
              </p>
              <PswdInput
                value={loginStates.password}
                onFocus={handleFocus}
                onChange={handleChange}
                name="password"
                placeholder="Вот тут придумайте*"
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__password-input error-login'
                    : 'authorisation-main-container_form-box__password-input'
                }></PswdInput>
              <PswdInput
                value={loginStates.secondpassword}
                onFocus={handleFocus}
                onChange={handleChange}
                name="secondpassword"
                placeholder="Вот тут повторите*"
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__password-input error-login'
                    : 'authorisation-main-container_form-box__password-input'
                }></PswdInput>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Продолжить</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : 'none-active'}>
                {loginStates.error}
              </p>
            </>
          )}
          {loginStates.activeBox === 'regnick' && (
            <>
              <p className="authorisation-main-container_form-box__caption">
                Придумайте, пожалуйста, никнейм
              </p>
              <p className="authorisation-main-container_form-box__hint">
                Например, KOsMO, prin, Xee_e
              </p>
              <AuthInput
                value={loginStates.login}
                onFocus={handleFocus}
                onChange={handleChange}
                name="login"
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__text-input error-login'
                    : 'authorisation-main-container_form-box__text-input'
                }
                placeholder="Никнейм*"></AuthInput>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Продолжить</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : 'none-active'}>
                {loginStates.error}
              </p>
            </>
          )}
          {loginStates.activeBox === 'regcategory' && (
            <>
              <p className="authorisation-main-container_form-box__caption">Кем вы являетесь?</p>
              <ChooseBtn
                onClick={chooseCategory}
                name="professional"
                className={
                  loginStates.userCategory === 'professional'
                    ? 'authorisation-main-container_form-box__choose-btn your-choice'
                    : loginStates.error
                      ? 'authorisation-main-container_form-box__choose-btn error-login'
                      : 'authorisation-main-container_form-box__choose-btn'
                }>
                Профессионал
              </ChooseBtn>
              <ChooseBtn
                onClick={chooseCategory}
                name="customer"
                className={
                  loginStates.userCategory === 'customer'
                    ? 'authorisation-main-container_form-box__choose-btn  your-choice'
                    : loginStates.error
                      ? 'authorisation-main-container_form-box__choose-btn error-login'
                      : 'authorisation-main-container_form-box__choose-btn'
                }>
                Заказчик
              </ChooseBtn>
              <ChooseBtn
                onClick={chooseCategory}
                name="organisation"
                className={
                  loginStates.userCategory === 'organisation'
                    ? 'authorisation-main-container_form-box__choose-btn  your-choice'
                    : loginStates.error
                      ? 'authorisation-main-container_form-box__choose-btn error-login'
                      : 'authorisation-main-container_form-box__choose-btn'
                }>
                Организация
              </ChooseBtn>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Далее</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : 'none-active'}>
                {loginStates.error}
              </p>
            </>
          )}
          {loginStates.activeBox === 'recmail' && (
            <>
              <p className="authorisation-main-container_form-box__caption">
                Укажите, пожалуйста, адрес эл. почты или телефон
              </p>
              <p className="authorisation-main-container_form-box__hint">
                Это нужно для подтверждения того, что вы владеете аккаунтом
              </p>
              <AuthInput
                value={loginStates.email}
                onFocus={handleFocus}
                onChange={handleChange}
                name="email"
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__text-input error-login'
                    : 'authorisation-main-container_form-box__text-input'
                }
                placeholder="Эл.Почта*"></AuthInput>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Продолжить</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : 'none-active'}>
                {loginStates.error}
              </p>
            </>
          )}
          {loginStates.activeBox === 'reccode' && (
            <>
              <p className="authorisation-main-container_form-box__caption">
                Введите, пожалуйста, код подтверждения
              </p>
              <p className="authorisation-main-container_form-box__hint">
                Мы отправили код подтверждения на почту: {loginStates.email}
              </p>
              <CodeInput
                onChange={handleChangeCode}
                onFocus={handleFocus}
                className={
                  loginStates.error
                    ? 'authorisation-main-container_form-box__code-input error-login'
                    : 'authorisation-main-container_form-box__code-input'
                }></CodeInput>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Продолжить</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : ''}>
                {loginStates.error}
              </p>
              {timespan === 0 ? (
                <p
                  onClick={() => alert('Отправил')}
                  className="authorisation-main-container_form-box__forgot-pswd-link">
                  Отправить код повторно
                </p>
              ) : (
                <p className="authorisation-main-container_form-box__hint">
                  Повторная отправка кода через<br></br>
                  {currentTime()}
                </p>
              )}
            </>
          )}
          {loginStates.activeBox === 'recpswd' && (
            <>
              <p className="authorisation-main-container_form-box__caption">
                Придумайте, пожалуйста, новый пароль
              </p>
              <PswdInput
                value={loginStates.password}
                onFocus={handleFocus}
                onChange={handleChange}
                name="password"
                placeholder="Вот тут придумайте*"
                className='authorisation-main-container_form-box__password-input'>
              </PswdInput>
              <PswdInput
                value={loginStates.secondpassword}
                onFocus={handleFocus}
                onChange={handleChange}
                name="secondpassword"
                placeholder="Вот тут повторите*"
                className='authorisation-main-container_form-box__password-input'>
              </PswdInput>
              {loading ? <BtnLoader className='authorisation-main-container_form-box--loader' /> : <SubmitBtn>Продолжить</SubmitBtn>}
              <p className={loginStates.error ? 'error-label' : 'none-active'}>
                {loginStates.error}
              </p>
            </>
          )}
        </form>

        {['login', 'regmail'].includes(loginStates.activeBox) && (
          <LoginLink
            onClick={() =>
              handleClearSwitchForm(loginStates.activeBox === 'login' ? 'regmail' : 'login')
            }
            link={loginStates.activeBox === 'login' ? 'Зарегистрироваться' : 'Войти'}>
            {loginStates.activeBox === 'login' ? 'Нет аккаунта?' : 'Есть аккаунт?'}
          </LoginLink>
        )}
      </div>
    </>
  );
};

export default Authorisation;
