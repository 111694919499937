import React, { useContext } from 'react';

import ProjectBtn from './components/components';

import { mainContext } from '../../App';

import { projects } from '../../global-constants/constants';

export default function Projects() {

    const { navigate } = useContext(mainContext);

    return (
        <div className='projects-container'>
            <header className='projects-container__header'>Widen-Проекты</header>
            {
                projects.map((p, idx) => {
                    return (
                        <ProjectBtn onClick={() => navigate(p.navigateTo)} key={`project-${idx}`} disabled={p.disabled} caption={p.caption} navigateTo={p.navigateTo}>{p.name}</ProjectBtn>
                    )
                })
            }
        </div>
    )
}