import { useContext } from "react";
import { Flluppsimage } from "../../custom_tags/custom_tags";

import techCat from "../../img/tech-work.gif";

import { mainContext } from "../../App";

export default function TechWork() {
    const { navigate } = useContext(mainContext)

    return (
        <div className="tech-work-container">
            <h1 className="tech-work-container__warning">Доступ запрещён</h1>
            <p className="tech-work-container__caption">Здесь ведутся технические работы</p>
            <Flluppsimage className="tech-work-container__image" src={techCat} alt='cat'/>
            <button onClick={() => navigate('/home')} className="tech-work-container__back-btn">На главную</button>
        </div>
    )
}