import { useState, useEffect } from "react";
import { Flluppsimage } from "../../../../custom_tags/custom_tags";
import FlluppsSvg from "../../../../fllupps_svg/FlluppsSvg";
import { BtnLoader } from "../../../loaders/loaders";
import { listColorClick, getArrToObj } from "../../../../global_function/function";
import {
    ForumStatus,
    AddContent,
    SelectCityFieldset,
    ForumName,
    ForumTextField
} from "./components/components";
import { CirclePrecentLoader } from "../../../loaders/loaders";
import { forumDataSet } from "../../../../global-constants/constants";
import EventFilter from "../../../event_filter/EventFilter";
import russian_cities from "../../../../data/russian_cities.json";

export default function FullForumCard(dataSet) {

    const {
        id = 0,
        mode = null,
        author_avatar = null,
        author_nickname = null,
        geoposition = null,
        name = null,
        status = 'opened',
        media = [],
        tags = []
    } = dataSet

    //Стейт форума
    const [forumState, setForumState] = useState({
        geoposition: geoposition,
        name: name || '',
        media: media ? [...media] : [],
        status: status
    })

    //Мой никнейм
    const myNickname = localStorage.getItem('myNickname');

    //Стейт для выбора действий
    const [openChoose, setOpenChoose] = useState(false);

    //Теги фильтра
    const [filter, setFilter] = useState(getArrToObj(tags ? tags : []));

    //Стейт для ошибки
    const [error, setError] = useState(null);

    //Проценты загрузки каждого файла
    const [precentages, setPrecentages] = useState({});

    //Функция преобразование кода статуса в текст
    function handleCodeStatusText(code = 'opened') {
        switch (code) {
            case 'opended':
                return 'открыто'
            case 'closed':
                return 'закрыто'
            default:
                return 'открыто'
        }
    };

    //Функция обновления полей
    function handleChange(code, value) {
        //Для создания
        setForumState((f) => {
            const tmp = { ...f };
            return {
                ...tmp,
                [code]: value
            }
        });
    };

    //Функция написания одного абзаца
    function handleEditText(e, id) {
        setForumState(f => {
            const tmp_media = [...f.media];
            const idx = tmp_media.findIndex(m => m.uuid === id);

            if (~idx) {
                tmp_media[idx].content.text = e.target.value;
            }

            return {
                ...f,
                media: [...tmp_media]
            }
        })
    }

    //Удаление контента
    function handleDeleteContent(id) {
        //Для создания
        setForumState((d) => {
            return {
                ...d,
                media: [...d.media.filter(m => m.uuid !== id)]
            };
        });
    }

    useEffect(() => {
        return () => {
            setPrecentages({});
        }
    }, [])

    return (
        <>
            <div className="full-forum-header-box">
                <div className="full-forum-header-box_author-box">
                    <Flluppsimage className="full-forum-header-box_author-box_avatar-box" src={author_avatar} alt='avatar' />
                    <div className="full-forum-header-box_author-box_sing-box">
                        <p className="full-forum-header-box_author-box_sing-box__nickname">{author_nickname}</p>
                        <p className="full-forum-header-box_author-box_sing-box__geolocation">
                            <FlluppsSvg type="geoposition" style={{ marginRight: '2px' }} width={8} height={11} fill="none" />
                            {forumState.geoposition || 'Не указано'}
                        </p>
                    </div>
                </div>
                {
                    false ?
                        (
                            <BtnLoader />
                        )
                        :
                        (
                            <div className="full-forum-header-box_btn-box">
                                {
                                    true && (
                                        <>
                                            <div onClick={() => setOpenChoose(!openChoose)} className="full-forum-header-box_btn-box_ellipsis-box">
                                                <span className="full-forum-header-box_btn-box_ellipsis-box--ellipse" />
                                                <span className="full-forum-header-box_btn-box_ellipsis-box--ellipse" />
                                                <span className="full-forum-header-box_btn-box_ellipsis-box--ellipse" />
                                            </div>
                                            {
                                                openChoose && (
                                                    <ul
                                                        className="full-forum-header-box_btn-box_btn-list"
                                                        onTouchStart={(e) => listColorClick(e.target)}
                                                        onMouseDown={(e) => listColorClick(e.target)}
                                                        onTouchEnd={(e) => listColorClick(e.target)}
                                                        onMouseUp={(e) => listColorClick(e.target)}
                                                    >
                                                        <li>
                                                            <FlluppsSvg type="pencil" width={15} height={15} fill="none" />
                                                            Редактировать
                                                        </li>
                                                        <li>
                                                            <FlluppsSvg type="basket" width={15} height={15} fill="none" />
                                                            Удалить
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    mode === 'create' && <button className="full-forum-header-box_btn-box__btn">Создать</button>
                                }
                                {
                                    mode === 'edit' && <button className="full-forum-header-box_btn-box__btn">Готово</button>
                                }
                                {
                                    error && <p className="full-forum-header-box_btn-box--error">{error}</p>
                                }
                            </div>
                        )
                }
            </div>
            <form onSubmit={() => { return; }} className="full-forum-box">
                <ForumStatus code='status' value={handleCodeStatusText(forumState.status)} mode={mode} onChange={(code, value) => handleChange(code, value)} />
                {
                    ['create', 'edit'].includes(mode) && (
                        <>
                            <EventFilter state={filter} table={forumDataSet} setState={setFilter} />
                            <SelectCityFieldset value={forumState.geoposition} onChange={(code, value) => handleChange(code, value)} code="geoposition" dataSet={[...russian_cities.map(c => c.name)]}>Выберите город:</SelectCityFieldset>
                        </>
                    )
                }
                <ForumName value={forumState.name} mode={mode} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                {
                    forumState.media.map((m, _) => {
                        if (m.type === 'text') {
                            return <ForumTextField key={`forum-${m.uuid}`} mode={mode} uuid={m.uuid} text={m.content?.text} onChange={handleEditText} onDelete={handleDeleteContent} />
                        }

                        if (m.type === 'image') {
                            return (
                                <>
                                    <Flluppsimage key={`forum-${m.uuid}`} className={`full-forum-box__image ${['create', 'edit'].includes(mode) ? "edit" : ""}`} src={m.content?.medium} alt="media">
                                        {
                                            ['create', 'edit'].includes(mode) && <FlluppsSvg onClick={() => handleDeleteContent(m.uuid)} className="full-forum-box__image open__close" type="close" width={14} height={14} fill="none" />
                                        }
                                    </Flluppsimage>
                                </>
                            )
                        }

                        return null;
                    })
                }
                {
                    Object.keys(precentages).length > 0 && (
                        <>
                            {
                                Object.keys(precentages).map((k, _) => {
                                    return (
                                        <>
                                            <div className="full-forum-box__image-loader">
                                                <CirclePrecentLoader precentage={precentages[k]} fontSize={20} className="full-forum-box__image-loader--loader" />
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                    )
                }
                {
                    ['create', 'edit'].includes(mode) && <AddContent setState={setForumState} setPrecentages={setPrecentages} />
                }
            </form>
        </>
    )
}