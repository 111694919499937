import React from 'react';

import { mainContext } from '../../App';

import { BackBtn } from '../NotFound/components/not_found_components';

import { Flluppsimage } from '../../custom_tags/custom_tags';

const NotFound = () => {

  const { goHome } = React.useContext(mainContext);

  return (
    <Flluppsimage src="https://cdn.fllupps.ru/img/backLoginColor.jpg" alt='back' className="not-found-conatiner">
      <h1 className="not-found-conatiner__header">
        К сожалению страница не найдена <span className="not-found-conatiner__header--emoji">😔</span>
      </h1>
      <BackBtn onClick={goHome}>Вернуться на главную</BackBtn>
    </Flluppsimage>
  );
};
export default NotFound;
