import useCancelToken from "../../../../custom_hooks/useCancelToken";
import useGetQuery from "../../../../custom_hooks/useGetQuery";
import { getNewswareById } from "../../../../service/routes/newsware/newsware";
import { publicationKey } from "../../../../global-constants/request_keys";
import PublicationLoader from "../../../content_loader/comments/PublicationLoader";
import Newsware from "../../../newsware/Newsware";
import FlluppsSvg from "../../../../fllupps_svg/FlluppsSvg";
import { mainContext } from "../../../../App";
import { useContext, useEffect } from "react";
import { FlluppsLinkText, Flluppsimage } from "../../../../custom_tags/custom_tags";
import { CircleLoader } from "../../../loaders/loaders";
import CommentLoader from "../../../content_loader/comments/CommentLoader";

export default function ForumMode() {

    const { search, setSearch } = useContext(mainContext);

    //токен отмены запроса
    const { cancelToken } = useCancelToken();

    //Определение, если ли мод на редактирование или создание
    const mode = search.get('mode');

    //Параметры для запроса
    const params = {
        'newsware': search.get('newsware'),
        'type': search.get('type'),
    };

    //Кастомный хук для запроса самого форума
    const { data: forum, isFetching, remove } = useGetQuery({
        key: publicationKey,
        params: params,
        enabled: !!search.toString() && mode !== 'create', //Это нужно, чтобы при создании не подтягивался запрос
        request: getNewswareById,
        handleCatch: () => { setSearch({}) },
        cancelToken: cancelToken
    });

    useEffect(() => {
        return (() => {
            remove();
        })
    }, [])


    return (
        <div className="full-forum-container">
            <header className="full-forum-container_header">
                <FlluppsSvg onClick={() => setSearch({}) } type='back_arrow' width={21} height={15} fill='none' />
                <h1 className="full-forum-container_header__caption">Форум</h1>
            </header>
            {
                isFetching ?
                    (
                        <PublicationLoader />

                    )
                    :
                    (
                        <Newsware dataSet={{ ...forum, type: 'forum', full: true, mode: mode }} />
                    )
            }
             <div className="forum-comments-container">
                <p className="forum-comments-container__count">100 ответов</p>
                <div className="forum-comments-container_comment-main-box choose-comment">
                    <div className="forum-comments-container_comment-main-box_comment-box">
                        <div className="forum-comments-container_comment-main-box_comment-box_header">
                            <div className="forum-comments-container_comment-main-box_comment-box_header_author-box">
                                <Flluppsimage className="forum-comments-container_comment-main-box_comment-box_header_author-box__avatar" />
                                <p className="forum-comments-container_comment-main-box_comment-box_header_author-box__name">Абоба</p>
                            </div>
                            <button className="forum-comments-container_comment-main-box_comment-box_header__best-btn">
                                Отметить как лучший
                            </button>
                        </div>
                        <FlluppsLinkText className="forum-comments-container_comment-main-box_comment-box__text">
                            @Afa казуал ссаный
                        </FlluppsLinkText>
                        <div className="forum-comments-container_comment-main-box_comment-box_footer">
                            <p className="forum-comments-container_comment-main-box_comment-box_footer__time">31 янв. в 23:40</p>
                            <div className="forum-comments-container_comment-main-box_comment-box_footer_like-box">
                                <FlluppsSvg
                                    className="forum-comments-container_comment-main-box_comment-box_footer_like-box__like"
                                    type="like"
                                    active
                                    width={25}
                                    height={21}
                                    fill="none"
                                />
                                <p className="forum-comments-container_comment-main-box_comment-box_footer_like-box__count">100+</p>
                            </div>
                            <button className="forum-comments-container_comment-main-box_comment-box_footer__unwrap-btn">Показать ответы: 100+</button>
                            <button className="forum-comments-container_comment-main-box_comment-box_footer__answer-btn">Ответить</button>
                        </div>
                        <CircleLoader className="forum-comments-container_comment-main-box_comment-box__reply-loader" />
                    </div>
                </div>
                <div className="forum-comments-container_comment-main-box best-comment">
                    <div className="forum-comments-container_comment-main-box_comment-box">
                        <div className="forum-comments-container_comment-main-box_comment-box_header">
                            <div className="forum-comments-container_comment-main-box_comment-box_header_author-box">
                                <Flluppsimage className="forum-comments-container_comment-main-box_comment-box_header_author-box__avatar" />
                                <p className="forum-comments-container_comment-main-box_comment-box_header_author-box__name">Абоба</p>
                            </div>
                            <p className="forum-comments-container_comment-main-box_comment-box_header__best">Лучший ответ</p>
                        </div>
                        <FlluppsLinkText className="forum-comments-container_comment-main-box_comment-box__text">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta laudantium ipsam, natus fuga ad illo? Velit neque repellendus quos temporibus aut veniam quo numquam omnis quidem nobis ad, iusto facere iste laborum magnam nulla possimus aspernatur praesentium autem. Temporibus consequuntur, vel animi repellendus tempore iusto blanditiis corporis sapiente ad consequatur.
                        </FlluppsLinkText>
                        <div className="forum-comments-container_comment-main-box_comment-box_footer">
                            <p className="forum-comments-container_comment-main-box_comment-box_footer__time">31 янв. в 23:40</p>
                            <div className="forum-comments-container_comment-main-box_comment-box_footer_like-box">
                                <FlluppsSvg
                                    className="forum-comments-container_comment-main-box_comment-box_footer_like-box__like"
                                    type="like"
                                    width={25}
                                    height={21}
                                    fill="none"
                                />
                                <p className="forum-comments-container_comment-main-box_comment-box_footer_like-box__count">100+</p>
                            </div>
                            <button className="forum-comments-container_comment-main-box_comment-box_footer__unwrap-btn">Скрыть ответы: 100+</button>
                            <button className="forum-comments-container_comment-main-box_comment-box_footer__answer-btn">Ответить</button>
                        </div>
                        <div className="forum-comments-container_comment-main-box_comment-box_reply-box">
                            <div className="forum-comments-container_comment-main-box_comment-box_reply-box_author-box">
                                <Flluppsimage className="forum-comments-container_comment-main-box_comment-box_reply-box_author-box__avatar" />
                                <p className="forum-comments-container_comment-main-box_comment-box_reply-box_author-box__name">Zalupa</p>
                            </div>
                            <FlluppsLinkText className="forum-comments-container_comment-main-box_comment-box_reply-box__text">@Adolf, собака ссаная - https://youtube.com/</FlluppsLinkText>
                            <div className="forum-comments-container_comment-main-box_comment-box_reply-box_footer-box">
                                <p className="forum-comments-container_comment-main-box_comment-box_reply-box_footer-box__time">31 янв. в 23:40</p>
                                <div className="forum-comments-container_comment-main-box_comment-box_reply-box_footer-box_like-box">
                                    <FlluppsSvg
                                        className="forum-comments-container_comment-main-box_comment-box_reply-box_footer-box_like-box__like"
                                        type="like"
                                        width={25}
                                        height={21}
                                        fill="none"
                                    />
                                    <p className="forum-comments-container_comment-main-box_comment-box_reply-box_footer-box_like-box__count">100+</p>
                                </div>
                                <button className="forum-comments-container_comment-main-box_comment-box_reply-box_footer-box__answer-btn">Ответить</button>
                            </div>
                        </div>
                        <button className="forum-comments-container_comment-main-box_comment-box__show-more-btn">Показать ещё</button>
                    </div>
                </div>
                <div className="forum-comments-container_comment-main-box best-comment">
                    <div className="forum-comments-container_comment-main-box_comment-box">
                        <div className="forum-comments-container_comment-main-box_comment-box_header">
                            <div className="forum-comments-container_comment-main-box_comment-box_header_author-box">
                                <Flluppsimage className="forum-comments-container_comment-main-box_comment-box_header_author-box__avatar" />
                                <p className="forum-comments-container_comment-main-box_comment-box_header_author-box__name">Абоба</p>
                            </div>
                            <button className="forum-comments-container_comment-main-box_comment-box_header__unbest-btn">Не лучший ответ</button>
                        </div>
                        <FlluppsLinkText className="forum-comments-container_comment-main-box_comment-box__text">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Soluta laudantium ipsam, natus fuga ad illo? Velit neque repellendus quos temporibus aut veniam quo numquam omnis quidem nobis ad, iusto facere iste laborum magnam nulla possimus aspernatur praesentium autem. Temporibus consequuntur, vel animi repellendus tempore iusto blanditiis corporis sapiente ad consequatur.
                        </FlluppsLinkText>
                        <div className="forum-comments-container_comment-main-box_comment-box_footer">
                            <p className="forum-comments-container_comment-main-box_comment-box_footer__time">31 янв. в 23:40</p>
                            <div className="forum-comments-container_comment-main-box_comment-box_footer_like-box">
                                <FlluppsSvg
                                    className="forum-comments-container_comment-main-box_comment-box_footer_like-box__like"
                                    type="like"
                                    active
                                    width={25}
                                    height={21}
                                    fill="none"
                                />
                                <p className="forum-comments-container_comment-main-box_comment-box_footer_like-box__count">100+</p>
                            </div>
                            <button className="forum-comments-container_comment-main-box_comment-box_footer__unwrap-btn">Показать ответы: 100+</button>
                            <button className="forum-comments-container_comment-main-box_comment-box_footer__answer-btn">Ответить</button>
                        </div>
                    </div>
                </div>
                <div className="forum-comments-container_comment-main-box">
                    <CommentLoader/>
                </div>
                <CircleLoader className="forum-comments-container__pagination-loader" />
            </div>
        </div>
    )
}