import { Conversion } from '../../../../global_function/function';
import { mainContext } from '../../../../App';
import { useContext } from 'react';

import { Flluppsimage, FlluppsLinkText } from '../../../../custom_tags/custom_tags';

import FlluppsSvg from '../../../../fllupps_svg/FlluppsSvg';

//Карточка поста
export default function EventCard(newsware) {
  //Разберём на необходимые элменты
  const {
    id = null,
    type = 'event',
    full = false,
    author_avatar = null,
    author_nickname = 'Bruh',
    preview_image = null,
    media = [],
    geoposition = null,
    register_date = new Date(),
  } = newsware;

  const { goToComments } = useContext(mainContext);

  return (
    <div className="event-box">
      <div className={`event-box_header-box${full ? '--full' : ''}`}>
        <Flluppsimage className='event-box_header-box_avatar-box' src={author_avatar} alt='avatar' />
        <div className="event-box_header-box_author-box">
          <p className="event-box_header-box_author-box__nickname">{author_nickname}</p>
          <p className="event-box_header-box_author-box__geolocation">
            <FlluppsSvg type="geoposition" style={{ marginRight: '2px' }} width={8} height={11} fill="none" />
            {geoposition}
          </p>
        </div>
      </div>
      {full ? (
        <>
          {
            media.map((m, _) => {
              if (m.type === 'text') {
                return <FlluppsLinkText key={`event-${m.uuid}`} className="event-box__text">{m.content?.text}</FlluppsLinkText>
              }

              if (m.type === 'image') {
                return <Flluppsimage key={`event-${m.uuid}`} className="event-box_media-box_image-box" src={m.content?.medium} alt="media" />
              }

              return null;
            })
          }
        </>
      ) : (
        <div className="event-box_media-box">
          <Flluppsimage onClick={() => goToComments(id, type)} className='event-box_media-box_image-box' src={preview_image?.medium} alt='media' />
          <div className="event-box_media-box_date-box">
            <p className="event-box_media-box_date-box__reg-sign">По регистрации</p>
            <p className="event-box_media-box_date-box__reg-date">
              {Conversion('shortTime', register_date)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
