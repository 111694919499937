export function NextArrow({ onClick = null }) {
    return (
        <svg className="fllupps-slider-container__slider-arrow slider-next" onClick={onClick} height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill='#7E52EE' d="M0,256.006C0,397.402,114.606,512.004,255.996,512C397.394,512.004,512,397.402,512,256.006 C512.009,114.61,397.394,0,255.996,0C114.606,0,0,114.614,0,256.006z" />
            <path fill='#7E52EE' d="M501.361,329.072c-0.141-0.117-0.236-0.279-0.388-0.388c-0.457-0.641-76.067-76.252-76.708-76.708 c-1.282-1.797-3.281-3.046-5.657-3.046h-39.694c-0.155-0.19-0.221-0.422-0.397-0.598c-0.29-0.29-0.657-0.423-0.98-0.652 c-0.228-0.323-137.459-137.788-137.748-138.077c-2.762-2.762-7.235-2.762-9.998,0L88.394,250.994 c-1.326,1.326-2.071,3.124-2.071,4.998c0,1.874,0.746,3.674,2.071,4.998c0,0,1.63,1.63,1.632,1.632l53.859,53.861 c0,0,153.914,153.917,183.834,183.839l1.107,1.107C411.558,476.923,476.772,411.771,501.361,329.072z" />
            <path fill='white' d="M418.608,248.93H110.452L239.789,119.6c2.762-2.762,2.762-7.235,0-9.997 c-2.762-2.762-7.235-2.762-9.997,0L88.393,250.994c-1.326,1.326-2.071,3.124-2.071,4.998c0,1.874,0.746,3.673,2.071,4.998 l141.399,141.406c1.381,1.381,3.189,2.071,4.998,2.071s3.618-0.69,4.998-2.071c2.762-2.762,2.762-7.235,0-9.997L110.465,263.07 h308.143c3.904,0,7.07-3.166,7.07-7.07C425.678,252.096,422.513,248.93,418.608,248.93z" />
        </svg>
    )
}

export function PrevArrow({ onClick = null }) {
    return (
        <svg className="fllupps-slider-container__slider-arrow slider-prev" onClick={onClick} height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512">
            <path fill='#7E52EE' d="M0,256.006C0,397.402,114.606,512.004,255.996,512C397.394,512.004,512,397.402,512,256.006 C512.009,114.61,397.394,0,255.996,0C114.606,0,0,114.614,0,256.006z" />
            <path fill='#7E52EE' d="M501.361,329.072c-0.141-0.117-0.236-0.279-0.388-0.388c-0.457-0.641-76.067-76.252-76.708-76.708 c-1.282-1.797-3.281-3.046-5.657-3.046h-39.694c-0.155-0.19-0.221-0.422-0.397-0.598c-0.29-0.29-0.657-0.423-0.98-0.652 c-0.228-0.323-137.459-137.788-137.748-138.077c-2.762-2.762-7.235-2.762-9.998,0L88.394,250.994 c-1.326,1.326-2.071,3.124-2.071,4.998c0,1.874,0.746,3.674,2.071,4.998c0,0,1.63,1.63,1.632,1.632l53.859,53.861 c0,0,153.914,153.917,183.834,183.839l1.107,1.107C411.558,476.923,476.772,411.771,501.361,329.072z" />
            <path fill='white' d="M418.608,248.93H110.452L239.789,119.6c2.762-2.762,2.762-7.235,0-9.997 c-2.762-2.762-7.235-2.762-9.997,0L88.393,250.994c-1.326,1.326-2.071,3.124-2.071,4.998c0,1.874,0.746,3.673,2.071,4.998 l141.399,141.406c1.381,1.381,3.189,2.071,4.998,2.071s3.618-0.69,4.998-2.071c2.762-2.762,2.762-7.235,0-9.997L110.465,263.07 h308.143c3.904,0,7.07-3.166,7.07-7.07C425.678,252.096,422.513,248.93,418.608,248.93z" />
        </svg>
    )
}

export function ProgressBar({ progress = 0 }) {
    return (
        <div className="fllupps-slider-container_progressbar-box">
            <div className="fllupps-slider-container_progressbar-box--progress" style={{width: `${progress}%`}} />
        </div>
    )
}