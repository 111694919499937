export function reducer(state, { type, payload }) {
    switch (type) {
        case "SET_USER_PROFILE":
            return{
                ...state,
                userName: payload.nickname,
                userPhoto: payload.photo
            }
        case "SET_NEWSWARE_FILTER_OPEN":
            return {
                ...state,
                isNewswareFilterOpen: !state.isNewswareFilterOpen
            };
        case "SET_MODAL":
            return{
                ...state,
                modalOption: payload.option
            }
        case "SET_FOOTER_TYPE":
            return {
                ...state,
                footType: payload
            };
        case "SET_MESSAGE_TEXT":
            return {
                ...state,
                messageText: payload
            };
        case "CHANGE_PAGE":
            return {
                ...state,
                page: payload
            };
        default:
            return state;
    }
}