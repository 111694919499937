import axios from "axios";
import { useEffect } from "react";

//Кастомный хук, который отменяет запрос
//ВАЖНО ОТМЕТИТЬ, ЕСЛИ ЗАПРОС ВЫПОЛНЯЕТСЯ НЕСКОЛЬКО РАЗ В ЗАВИСИМОСТИ ОТ СТЕЙТА ЕГО НУЖНО ПОМЕЩАТЬ В АРГУМЕНТЫ
/**
 * 
 * @param {object} args Массив зависимости
 */
export default function useCancelToken(args = []) {

    const source = axios.CancelToken.source();

    useEffect(() => {
        return () => {
            if (source) {
                source.cancel();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...args]);

    return {
        cancelToken: source.token
    }
}