import { Skeleton } from "../../../custom_tags/custom_tags"

export default function NewswareLoader() {
    return (
        <div className="newsware-skeleton">
            <Skeleton className="newsware-skeleton__avatar" />
            <Skeleton className="newsware-skeleton__text" />
            <Skeleton className="newsware-skeleton__preview" />
            <Skeleton className="newsware-skeleton__stats" />
        </div>
    )
}