import { useContext } from "react";
import { Flluppsimage } from "../../../../custom_tags/custom_tags";
import { Conversion } from "../../../../global_function/function";
import { mainContext } from "../../../../App";

export default function PreviewForumCard(newsware) {
    
    //Разберём на необходимые элменты
    const {
        id = null,
        author_avatar = null,
        author_nickname = 'Bruh',
        name = 'Здесь обсуждают?',
        tags = [],
        reply_count = 0
    } = newsware;

    const { setSearch } = useContext(mainContext);

    //Функция перехода к развёрнутому форуму
    function handleGoFullForum(id){
        setSearch({newsware: id, type: "forum"});
    }

    return (
        <>
            <p className="forum-nickname">{author_nickname}</p>
            <Flluppsimage src={author_avatar} alt="avatar" className="forum-avatar" />
            <div onClick={() => handleGoFullForum(id)} className="forum-box">
                <p className="forum-box__name">{name}</p>
                <div className="forum-box_tags-box">
                    {
                        tags.map((t, _) => {
                            return (
                                <>
                                    {
                                        !!t?.value.length && (
                                            <>
                                                {t.value.map((v, _) => {
                                                    return (
                                                        <span key={`${v}-${id}`} className="forum-box_tags-box__tag">{v}</span>
                                                    )
                                                })}
                                            </>
                                        )
                                    }
                                </>
                            )
                        })
                    }
                </div>
                <p className="forum-box__comment-count">ответов: {Conversion('hundredPlus', reply_count)}</p>
            </div>
        </>
    )
}