import { Route, Routes } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { FlluppsMainLoader } from '../global_components/loaders/loaders';

import Home from '../pages/Home/Home';
import Messenger from '../pages/Messenger/Messenger';
import Profile from '../pages/Profile/Profile';
import NotFound from '../pages/NotFound/NotFound';
import Authorisation from '../pages/Authorisation/Authorisation';
import Projects from '../pages/Projects/Projects';

const Forum = lazy(() => import('../pages/Forum/Forum'));
const Search = lazy(() => import('../pages/Search/Search'));
const Partnerships = lazy(() => import("../pages/Partnerships/Partneships"))

const Router = () => {
    return (
        <Routes>
            <Route path='/' element={<Home />} />
            <Route index path="/home" element={<Home />} />
            <Route path="/search" element={<Suspense fallback={<FlluppsMainLoader />}><Search /></Suspense>} />
            <Route path="/partnerships" element={<Suspense fallback={<FlluppsMainLoader />}><Partnerships /></Suspense>} />
            <Route path="/messenger" element={<Messenger />} />
            <Route path="/forum" element={<Suspense fallback={<FlluppsMainLoader />}><Forum /></Suspense>} />
            <Route path="/profile/:nickname" element={<Profile />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/login" element={<Authorisation />} />
            <Route path='/projects' element={<Projects />} />
        </Routes>
    )
}

export default Router;