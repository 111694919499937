import FlluppsSvg from "../../../../../fllupps_svg/FlluppsSvg"

//Шапка комментариев
export const HeaderComments = ({ onClick, children }) => {
    return (
        <header className="comments-container__header">
            <FlluppsSvg onClick={onClick} type='back_arrow' width={21} height={15} fill='none' />
            <h1 className="comments-container__header--name">{children}</h1>
        </header>
    )
}
