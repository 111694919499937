import cry_gif from '../../img/no_content.gif';

import { Flluppsimage } from '../../custom_tags/custom_tags';

export default function NoFoundContent({ children }) {
    return (
        <div className='no-content-box'>
            <Flluppsimage className='no-content-box__image' src={cry_gif} alt='no_content'/>
            <p className='no-content-box__caption'>{children}</p>
        </div>
    )
}



