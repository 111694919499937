import axios from "axios";

const authFlluppsApi = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Allow-Credentials': true,
        'Access-Control-Allow': 'Origin, X-Requested-With, Content-Type, Accept'
    }
});

authFlluppsApi.interceptors.request.use(
    (config) => {
        // Получаем токен
        const token = localStorage.getItem('token'); //Получение токена из loacalStorage;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


authFlluppsApi.interceptors.response.use((res) => {
    if(res.headers["authorization"]){
        localStorage.setItem("token", res.headers["authorization"].replace("Bearer ", ""));
    }
    return Promise.resolve(res.data || res);
},
    (error) => {
        return Promise.reject(error.response?.data || error.message);
    }
);

export default authFlluppsApi;
