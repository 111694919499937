import { Skeleton } from "../../../custom_tags/custom_tags";

export default function PartnershipLoader() {
    return (
        <div className="partnership-skeleton">
            <div className="partnership-skeleton_header-box">
                <div className="partnership-skeleton_header-box_author-box">
                    <Skeleton className="partnership-skeleton_header-box_author-box__avatar" />
                    <div className="partnership-skeleton_header-box_author-box_name-box">
                        <Skeleton className="partnership-skeleton_header-box_author-box_name-box__name" />
                        <Skeleton className="partnership-skeleton_header-box_author-box_name-box__geoposition" />
                    </div>
                </div>
                <Skeleton className="partnership-skeleton_header-box__btn" />
            </div>
            <Skeleton className="partnership-skeleton__border" />
            <div className="partnership-skeleton_text-box">
                <Skeleton className="partnership-skeleton_text-box__name" />
                <Skeleton className="partnership-skeleton_text-box__text" />
            </div>
            <div className="partnership-skeleton_text-box">
                <Skeleton className="partnership-skeleton_text-box__name" />
                <Skeleton className="partnership-skeleton_text-box__text" />
            </div>
            <div className="partnership-skeleton_text-box">
                <Skeleton className="partnership-skeleton_text-box__name" />
                <Skeleton className="partnership-skeleton_text-box__text" />
            </div>
            <div className="partnership-skeleton_text-box">
                <Skeleton className="partnership-skeleton_text-box__name" />
                <Skeleton className="partnership-skeleton_text-box__text" />
            </div>
            <div className="partnership-skeleton_text-box">
                <Skeleton className="partnership-skeleton_text-box__name" />
                <Skeleton className="partnership-skeleton_text-box__text" />
            </div>
            <div className="partnership-skeleton_media-box">
                <Skeleton className="partnership-skeleton_media-box__image" />
                <Skeleton className="partnership-skeleton_media-box__image" />
                <Skeleton className="partnership-skeleton_media-box__image" />
            </div>
        </div>
    )
}