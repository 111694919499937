//Кастомный хук для обновления данных POST, PUT, DELETE и т.д

import { useMutation } from "react-query";

/**
 * 
 * @param {string} key ключ обновления 
 * @param {object | string} params параметры запроса больше для delete
 * @param {Function} request функция запроса
 * @param {Function} handleSuccess функция при удачном завершении запроса
 * @param {Function} handleCatch функция при ошибке
 * @param {Function} handleMutate обработчик до запроса
 * @param {Function} handleFinnaly функция, которая выполняется всегда
 * @param {AxiosToken} cancelToken токен отмены запроса
 * @param {Boolean} enabled необходимо для последовательных запросов
 */
export default function useUpdate({ key = '', params = null, request = null, cancelToken = null, handleMutate = null, handleSuccess = null, handleCatch = null, handleFinnaly = null }) {

    //Функция отправки запроса
    async function fetchData(params, data) {

        if (params && data) {
            return await request(params, data, cancelToken);
        }

        if (params) {
            return await request(params, cancelToken);
        }

        if (data) {
            return await request(data, cancelToken);
        }

        return await request(cancelToken);
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: [key],
        mutationFn: async (data) => await fetchData(params, data),
        onMutate: () => {
            handleMutate?.();
        },
        onSuccess: (data) => {
            handleSuccess?.(data);
        },
        onError: (error) => {
            handleCatch?.(error)
        },
        onSettled: (data, error) => {
            handleFinnaly?.(data, error)
        }
    });

    return{
        mutate,
        isLoading
    }
}