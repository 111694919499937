import { useEffect, useContext } from 'react';
import Newsware from '../../../newsware/Newsware';
import { HeaderComments } from './components/components';
import useCancelToken from '../../../../custom_hooks/useCancelToken';
import PublicationLoader from '../../../content_loader/comments/PublicationLoader';
import { getNewswareById } from '../../../../service/routes/newsware/newsware';
import useGetQuery from '../../../../custom_hooks/useGetQuery';
import { publicationKey } from '../../../../global-constants/request_keys';
import { mainContext } from '../../../../App';

const Comments = () => {

    const { search, setSearch } = useContext(mainContext);

    //токен
    const { cancelToken } = useCancelToken();

    //Параметры для запроса
    const params = {
        'newsware': search.get('newsware'),
        'type': search.get('type'),
        'username': localStorage.getItem('myNickname')
    };

    //Используем кастомный хук
    const { data: newsware, isFetching, remove } = useGetQuery({
        key: publicationKey,
        params: params,
        request: getNewswareById,
        enabled: !!search.toString(),
        retry: 3,
        handleCatch: () => { setSearch({}); },
        cancelToken: cancelToken
    });

    useEffect(() => {
        return (() => {
            remove();
        })
    }, []);

    //Функция которая возвразает по типу корректное название
    function getCurrentNameOfPub() {
        switch (newsware?.type) {
            case 'partnership':
                return 'Партнёрство';
            case 'event':
                return 'Мероприятие';
            case 'post':
                return 'Пост';
            case 'case':
                return 'Кейс';
            default:
                return 'Запись';
        }
    }

    return (
        <>
            <div className="comments-container">
                <HeaderComments onClick={() => setSearch({})}>{getCurrentNameOfPub()}</HeaderComments>
                {
                    isFetching ?
                        (
                            <PublicationLoader />

                        )
                        :
                        (
                            <>
                                <Newsware dataSet={{ ...newsware, full: true }} />
                            </>
                        )
                }
            </div>
        </>
    );
};

export default Comments;