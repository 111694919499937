import './css/modal.css';
import { createPortal } from 'react-dom';

import PartnershipMode from './windows/partnersip/PartnershipMode';
import Comments from './windows/comments/Comments';

import { mainContext } from '../../App';
import { useContext, useState, useEffect } from 'react';
import ForumMode from './windows/forum/ForumMode';


function Modal() {

    const { closeModal, modalOption, search, openModal } = useContext(mainContext);

    const [isOpen, setIsOpen] = useState(false);

    function handleClose() {
        setIsOpen(false);
        setTimeout(() => {
            closeModal();
        }, 400); // Задержка должна соответствовать длительности анимации
    };

    function handleOpen(option = null) {
        setTimeout(() => {
            setIsOpen(true);
        }, 50)
        openModal(option);
    }

    const modal = document.getElementById('modal');

    useEffect(() => {
        const urlSearch = search.toString();

        if (urlSearch) {
            const type = search.get('type');
            const mode = search.get('mode');

            if (type) {
                if (type === "forum") {
                    handleOpen("forum");
                } else if (type === 'partnership' && mode) {
                    handleOpen("partnership");
                } else {
                    handleOpen('comments');
                }
            }
        } else {
            handleClose();
        }

    }, [search.toString()])

    if (modalOption) {
        return createPortal((
            <>
                <div className={`modal_container ${isOpen ? "modal_container--open" : "modal_container--close"}`}>
                    {modalOption === 'partnership' && <PartnershipMode />}
                    {modalOption === 'comments' && <Comments />}
                    {modalOption === 'forum' && <ForumMode />}
                    {/* <Preview onClose={closeModal} /> */}
                    {/* <Confirmation /> */}
                    {/* <ChatActions /> */}
                </div>
            </>
        ), modal)
    }

    return null;
};

export default Modal;