import { Link } from 'react-router-dom';
import FlluppsSvg from '../../../fllupps_svg/FlluppsSvg';
import { listColorClick } from "../../../global_function/function";

//Компонент фильтра новостной ленты
export const HeaderFilter = ({ className, onClick }) => {
    return (
        <ul
            onClick={onClick}
            className={className}
            onTouchStart={(e) => listColorClick(e.target)}
            onMouseDown={(e) => listColorClick(e.target)}
            onTouchEnd={(e) => listColorClick(e.target)}
            onMouseUp={(e) => listColorClick(e.target)}
        >
            {/* <li name='case' className='filter_newsware_item'>Кейсы</li> */}
            <li name='post' className='filter-newsware-box__item'>Посты</li>
            <li name='event' className='filter-newsware-box__item'>Мероприятия</li>
            <li name='all' className='filter-newsware-box__item'>Всё вместе</li>
        </ul>
    )
}

//Компонент Шапки главной страницы
export const Header = ({ onClick, className }) => {
    return (
        <header className="header-box">
            <div data-version={`v${process.env.REACT_APP_VERSION}`} onClick={onClick} className="header-box_logo-box">
                <h1 className="header-box_logo-box__name">Fllupps</h1>
                <div className={className}>
                    <span className='header-box_logo-box_arrow-box--arrow-right'></span>
                    <span className='header-box_logo-box_arrow-box--arrow-left'></span>
                </div>
            </div>
            <Link className="header-box__search" to="/search">
                <FlluppsSvg type='search' width={20} height={20} fill='none' />
            </Link>
        </header>
    )
}