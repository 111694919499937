import authFlluppsApi from "../../api/auth_api";

//Регистрация пользователя
/**
 * "email": "doe@dope.com",
    "password": "secured",
    "username": "johndoe"
 */
export const Registration = (data) => {
    return authFlluppsApi.post('/signup', JSON.stringify(data));
}

//Авторизация
export const Auth = (data) => {
    return authFlluppsApi.post('/signin', JSON.stringify(data));
}

//Обновление токена
export const UpdateToken = () => {
    return authFlluppsApi.get('/token')
}