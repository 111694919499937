/**
 *
 * Во всех компонентах участвует mode, который может быть [null, edit, create]
 */

import { useEffect, useState } from "react"
import { Expandingarea, Flluppsimage, ValidationInput, SelectSearch } from "../../../../../custom_tags/custom_tags";
import FlluppsSlider from "../../../../slider/FlluppsSlider";
import { SplideSlide } from "@splidejs/react-splide";
import { CirclePrecentLoader } from "../../../../loaders/loaders";
import { getUUID, handleAddFiles } from "../../../../../global_function/function";
import { listColorClick } from "../../../../../global_function/function";
//Компонент статуса
export function PartnershipStatus({ code, value = 'открыто', onChange, mode = null }) {

    //Стейт открытия и закрытия редактирования статуса
    const [open, setOpen] = useState(false)

    //Функция выбора статуса
    function handleClick(e) {
        if (e.target.tagName === "LI") {
            setOpen(false);
            onChange(code, e.target.getAttribute('name'));
        }
    }

    return (
        <>
            <p className="full-partnership-box__status">
                Статус: {value}
                {
                    mode === 'edit' && <span onClick={() => setOpen(!open)} className={`full-partnership-box__status--choose-triangle ${open ? "open" : ""}`} />
                }
            </p>
            {
                mode === 'edit' && open && (
                    <ul
                        onClick={handleClick}
                        className="full-partnership-box__status_choose-list"
                        onTouchStart={(e) => listColorClick(e.target)}
                        onMouseDown={(e) => listColorClick(e.target)}
                        onTouchEnd={(e) => listColorClick(e.target)}
                        onMouseUp={(e) => listColorClick(e.target)}
                    >
                        <li name="opened" className="full-partnership-box__status_choose-list__item">Открыто</li>
                        <li name='in_process' className="full-partnership-box__status_choose-list__item">В процессе</li>
                        <li name='closed' className="full-partnership-box__status_choose-list__item">Закрыто</li>
                    </ul>
                )
            }
        </>
    )
}

//Компонент, который оборачивает в fieldset
export function FieldSetBox({ code = null, label = null, children }) {
    return (
        <fieldset name={code} className="full-partnership-box_form-field">
            <p className="full-partnership-box_form-field__label">{label}</p>
            {children}
        </fieldset>
    )
}


//Компонент с названием проекта и его редактировании
export function PartnershipName({ value = '', onChange, mode = null }) {
    return (
        <>
            {
                ['create', 'edit'].includes(mode) ?
                    (
                        <FieldSetBox code='name' label="Название проекта:">
                            <ValidationInput name='name' value={value} onChange={onChange} type="text" className="full-partnership-box_form-field__input" />
                        </FieldSetBox>
                    )
                    :
                    (
                        <h1 className="full-partnership-box__name">{value}</h1>
                    )
            }
        </>
    )
}

//Компонент со списком партнёров
export function ParnershipPartners({ onClick, partners = [], mode = null }) {
    return (
        <>
            {
                (partners.length > 0 || mode) &&
                (
                    <FieldSetBox code='partners' label="Парнёры">
                        <ul className="full-partnership-box_form-field_partners-list">
                            {
                                partners.map((p, _) => {
                                    return (
                                        <Flluppsimage src={p.profile_photo} alt="partner" key={`partner-${p.id}`} className="full-partnership-box_form-field_partners-list__item" />
                                    )
                                })
                            }
                            {
                                mode && (
                                    <li>
                                        <button onClick={onClick} className="full-partnership-box_form-field_partners-list__btn">Пригласить+</button>
                                    </li>
                                )
                            }
                        </ul>
                    </FieldSetBox>
                )
            }
        </>
    )
}

//Компонент с полем информации о партнёрстве
export function ParnershipFieldset({ children, value = '', code, onChange, mode = null }) {
    return (
        <FieldSetBox code={code} label={children}>
            <Expandingarea isRequired value={value} className='full-partnership-box_form-field__text-area' disabled={!mode} onChange={(e) => onChange(code, e.target.value)} />
        </FieldSetBox>
    )
};

//Компонент с выбором города
export function SelectCityFieldset({ children, dataSet = [], value = '', code, onChange }) {
    return (
        <FieldSetBox code={code} label={children}>
            <SelectSearch
                boxClassName="full-partnership-box_form-field_select-box"
                inputClassName='full-partnership-box_form-field_select-box__input'
                value={value}
                dataSet={dataSet}
                setValue={(value) => onChange(code, value)}
            />
        </FieldSetBox>
    )
}

//Компонент с медия партнёрства
export function PartnershipMedia({ media = [], mode = null, setPartnershipState }) {

    //Проценты загрузки каждого файла
    const [precentages, setPrecentages] = useState({});

    //Ошибка загрузки
    const [error, setError] = useState(null);

    //Удаление фото
    function handleDeleteMedia(id) {

        //Для создания
        setPartnershipState((d) => {
            return {
                ...d,
                media: [...d.media.filter(m => m.uuid !== id)]
            };
        });
    }

    useEffect(() => {
        return () => {
            setPrecentages({});
        }
    }, [])

    return (
        <>
            {
                (media.length > 0 || mode) &&
                (
                    <div className={`full-partnership-box_media-box ${mode ? 'edit' : ''}`}>
                        <p className="full-partnership-box_media-box__label">Медиа:</p>
                        {
                            mode ?
                                (
                                    <>
                                        <div className="full-partnership-box_media-box__add-btn">
                                            <input
                                                id='partnerMedia'
                                                multiple
                                                onChange={(e) => handleAddFiles(e, setError, setPrecentages, (url) => {
                                                    setPartnershipState((d) => {
                                                        return {
                                                            ...d,
                                                            media: [...d.media, { uuid: getUUID(), type: 'image', content: url }]
                                                        }
                                                    });
                                                })}
                                                type='file'
                                                accept="image/*" />
                                            <label htmlFor="partnerMedia" className="full-partnership-box_media-box__add-btn--label">+Добавить медиа</label>
                                        </div>
                                        <div className="full-partnership-box_media-box_edit-image-box">
                                            {
                                                media.map(m => {
                                                    return (
                                                        <Flluppsimage key={m.uuid} className="full-partnership-box_media-box_edit-image-box__image" src={m.content?.low} alt='media'>
                                                            <svg onClick={() => handleDeleteMedia(m.uuid)} className="full-partnership-box_media-box_edit-image-box__image--drop" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M1 1L7 7M13 13L7 7M7 7L13 1M7 7L1 13" stroke="white" />
                                                            </svg>
                                                        </Flluppsimage>
                                                    )
                                                })
                                            }
                                            {
                                                Object.keys(precentages).length > 0 && (
                                                    <>
                                                        {
                                                            Object.keys(precentages).map((k, _) => {
                                                                return (
                                                                    <>
                                                                        <div key={k} className="full-partnership-box_media-box_edit-image-box__image-loading">
                                                                            <CirclePrecentLoader precentage={precentages[k]} fontSize={20} className="full-partnership-box_media-box_edit-image-box__image-loading--loader" />
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                        {
                                            error && <p className="full-partnership-box__error">{error}</p>
                                        }
                                    </>
                                )
                                :
                                (
                                    <FlluppsSlider sliderClassName="full-partnership-box_media-box__slider">
                                        {
                                            media.map((m, _) => {
                                                return (
                                                    <SplideSlide key={`media-${m.uuid}`}>
                                                        <Flluppsimage onClick={() => alert('Ага')} className="full-partnership-box_media-box_image-box" src={m.content?.medium} alt="media" />
                                                    </SplideSlide>
                                                )
                                            })
                                        }
                                    </FlluppsSlider>
                                )
                        }
                    </div>
                )
            }
        </>
    )
}

//Компонент с предыдущими партнёрствами
export function PrevPartnerships({ prev_partnerships = [] }) {
    return (
        <>
            {
                prev_partnerships.length > 0 &&
                (
                    <div className="full-partnership-box_prev-partnership-box">
                        <p className="full-partnership-box_prev-partnership-box__label">Предыдущие партнерства автора:</p>
                        {/* <Swiper
                            initialSlide={0}
                            spaceBetween={-200}
                            grabCursor={true}
                            centeredSlides={false}
                            loop={false}
                            slidesPerView={1}
                            className="full-partnership-box_prev-partnership-box__swiper"
                        >
                            {
                                prev_partnerships.map((pp, _) => {
                                    return (
                                        <SwiperSlide key={pp.id}>
                                            <Flluppsimage src={pp.preview_image} className="full-partnership-box_prev-partnership-box__image">
                                                <p className="full-partnership-box_prev-partnership-box__image--name">{pp.name}</p>
                                                {
                                                    pp.partners.length > 0 &&
                                                    (
                                                        <div className="full-partnership-box_prev-partnership-box__image_partners-box">
                                                            {
                                                                pp.partners.map((p, idx) => {
                                                                    return <Flluppsimage key={p.id} src={p.profile_photo} className={`full-partnership-box_prev-partnership-box__image_partners-box--partner-${idx + 1}`} />
                                                                })
                                                            }
                                                        </div>
                                                    )
                                                }
                                            </Flluppsimage>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper> */}
                    </div>
                )
            }
        </>
    )
}