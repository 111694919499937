import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { Flluppsimage } from '../../custom_tags/custom_tags';

import pagin_loader from "../../img/pagin_loader.gif";

export function BtnLoader({ className = '' }) {
    return (
        <p className={`btn-loader ${className}`}>
            Ожидайте
            <span className="btn-loader__circle circle--1">.</span>
            <span className="btn-loader__circle circle--2">.</span>
            <span className="btn-loader__circle circle--3">.</span>
        </p>
    )
}

export function PaginationLoader() {
    return <Flluppsimage className='pagination-loader' src={pagin_loader} />
}

//Класснейм в пропе нужен для задачи широты высоты и позиционирования
export function CircleLoader({ className = '' }) {
    return <div className={`circle-loader ${className}`} />
}

//Круг с процентами 
export function CirclePrecentLoader({ className = '', fontSize = 0, precentage = 0 }) {
    return (
        <CircularProgressbar
            value={precentage}
            text={`${precentage}%`}
            styles={
                buildStyles({
                    rotation: 0.25,
                    strokeLinecap: 'butt',
                    textSize: `${fontSize}px`,
                    pathTransitionDuration: 0.5,
                    pathColor: 'rgb(126, 82, 238)',
                    textColor: '#000000',
                    trailColor: '#BABABA',
                    backgroundColor: 'transparent',
                    transition: 'stroke-dashoffset 0.5s ease 0s',
                    transform: 'rotate(0.25turn)',
                    transformOrigin: 'center center',
                })
            }
            className={className}
        />
    )
}

//Основной лоадер на весь экран
export function FlluppsMainLoader() {
    return (
        <Flluppsimage src="http://cdn.fllupps.ru/img/backLoginColor.jpg" className="fllupps-main-loader-container">
            <h1 className="fllupps-main-loader-container__name">
                <span>F</span>
                <span>L</span>
                <span>L</span>
                <span>U</span>
                <span>P</span>
                <span>P</span>
                <span>S</span>
            </h1>
        </Flluppsimage>
    )
}