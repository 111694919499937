import { Link } from "react-router-dom"
import FlluppsSvg from "../../../fllupps_svg/FlluppsSvg"
import { mainContext } from "../../../App"
import { memo, useContext } from "react"
import { Flluppsimage, Expandingarea } from "../../../custom_tags/custom_tags"

//Футер для навигации по страницам
function NavigationFooterMain() {

    const { page } = useContext(mainContext);

    const myNickname = localStorage.getItem('myNickname');

    return (
        <ul className="footer-container_navigation-list">
            <li>
                <Link className="footer-container_navigation-list__item" to="/home">
                    <FlluppsSvg type='home' width={27} height={25} active={page === 'home'} />
                    <p className={`footer-container_navigation-list__item__caption ${page === 'home' ? "active" : ""}`}>Лента</p>
                </Link>
            </li>
            <li>
                <Link className="footer-container_navigation-list__item" to="/projects">
                    <FlluppsSvg type='projects' width={25} height={25} active={['projects', 'partnerships'].includes(page)} />
                    <p className={`footer-container_navigation-list__item__caption ${['projects', 'partnerships'].includes(page) ? "active" : ""}`}>Проекты</p>
                </Link>
            </li>
            <li>
                <Link className="footer-container_navigation-list__item" to="/messenger">
                    <FlluppsSvg type='messenger' width={24} height={25} active={page === 'messenger'}>
                        <circle cx="20" cy="4" r="4" fill="#FD0909" />
                    </FlluppsSvg>
                    <p className={`footer-container_navigation-list__item__caption ${page === 'messenger' ? "active" : ""}`}>Сообщения</p>
                </Link>
            </li>
            <li>
                <Link className="footer-container_navigation-list__item" to="/forum">
                    <FlluppsSvg type='forum' width={35} height={25} active={page === 'forum'} />
                    <p className={`footer-container_navigation-list__item__caption ${page === 'forum' ? "active" : ""}`}>Форум</p>
                </Link>
            </li>
            <li>
                <Link className="footer-container_navigation-list__item" to={`/profile/${myNickname}`}>
                    <FlluppsSvg type='profile' width={22} height={25} active={page.includes('profile')} />
                    <p className={`footer-container_navigation-list__item__caption ${page.includes('profile') ? "active" : ""}`}>Профиль</p>
                </Link>
            </li>
        </ul>
    )
}

//футер для написания сообщения
function TextFooterMain({ value = '', handleChange }) {
    return (
        <div className="footer-container_text-container">
            <Flluppsimage className="footer-container_text-container__avatar" src="https://4x4photo.ru/wp-content/uploads/2023/05/70e6dbdc-1661-41c2-a033-179404ca8b9f.jpg" alt="avatar" />
            <div className="footer-container_text-container_text-box">
                <Expandingarea value={value} onChange={handleChange} className="footer-container_text-container_text-box__text" placeholder="Написать..." />
                <FlluppsSvg type="send" width={17} height={18} active={value.length} />
            </div>
        </div>
    )
}

export const NavigationFooter = NavigationFooterMain;
export const TextFooter = memo(TextFooterMain, (prev, next) => prev.value === next.value)