import { v4 as uuidv4 } from 'uuid';
import { formatDistance, format } from "date-fns";
import { ru } from "date-fns/locale";
import { UploadFile } from '../service/routes/file/file';

//Функция конвертации
export function Conversion(type, stats, page = '') {
    let element = null;
    switch (type) {
        //Числовой тип 1_000_000 1М и т.д
        case 'count':
            element =
                stats >= 1e7 ?
                    '10+м.'
                    : stats >= 1e6 ?
                        (stats / 1e6).toFixed(2) + 'м.'
                        : stats >= 1e3
                            ? (stats / 1e3).toFixed(1) + 'т.'
                            : stats;
            break;
        //Правильное скланение слова комментриев
        case 'comments':
            element =
                stats % 10 === 1 && stats % 100 !== 1
                    ? 'комментарий'
                    : stats % 10 === 0 || stats % 10 >= 5 || stats % 100 === 1
                        ? 'комментариев'
                        : 'комментария';
            break;
        //Ограничение кол-ва символов в новостной ленте (в тексте)
        case 'previewSign':
            element = {
                large: stats?.length > 180,
                sign: stats?.length > 180 ? stats.substr(0, 177) + '...' : stats
            };
            break;
        //Конвертация количества, если больше 100, то 100+
        case 'hundredPlus':
            element = stats > 100 ? '100+' : stats;
            break;
        //Конвертация времени
        case 'time':
            let now = new Date();
            now.setHours(now.getHours() + 5);
            stats.setHours(stats.getHours() + 5);
            if (now - stats > (1000 * 60 * 60 * 24)) {
                element = format(stats, 'dd MMM, в hh:mm', { locale: ru });
            } else {
                element = formatDistance(stats, now, { locale: ru }).replace('около', '') + ' назад';
            }
            break;
        case 'shortTime':
            element = format(stats, 'dd.MM.yyyy');
            break;
        default:
            return element;
    }
    return element;
}

//Функция изменения цвета по нажатию(Только для списка, где присутствует li)
export function listColorClick(element) {
    element?.tagName === 'LI' ?
        element.classList?.toggle('list-color-click')
        : element.closest("li")?.classList?.toggle('list-color-click');
};

//Функция, которая определяет, что человек выбрал фильтры
export function getBoolCountFilter(globalFilter) {
    for (let key in globalFilter) {
        if (globalFilter[key].length > 0) {
            return true;
        }
    }
    return false
}

//Подсчёт количества выбранных фильтров по всем категориям
export function getCountAllCategoryFilter(globalFilter) {
    let count = 0;
    for (let key in globalFilter) {
        count += globalFilter[key].length
    }
    return count;
}

//Генерация uuid
export function getUUID() {
    return uuidv4();
}

//Генерация получаения из массива в объект, для филтра глобального 
/*
    [
        {
            code : ...,
            name : ...,
            value: []
        },
        {
        }         
    ] = > 
     {
        code1: value1,
        code2: value2
     }
*/
export function getArrToObj(arr) {
    const result = {};
    arr.forEach(a => {
        result[a.code] = [...a.value]
    });
    return result;
}

//Генерация категорий фильтра в query параметры code: [val1, val2] => code: "val1,val2"
export function generateCategoryQuery(tags = null) {
    if (tags) {
        Object.keys(tags).forEach(t => {
            tags[t] = tags[t].join(',');
        })
    }
    return tags;
}

//Загрузчик файлов
/**
 * 
 * @param {DOMElement} e //Откуда вызвалось это событие
 * @param {Function} handleError //Обработчик ошибки
 * @param {Function} setPrecentages //Обработчик стейтов состояния загрузки(!!ОН обязательный и всегда объект)
 * @param {Function} handleSuccess //Обработчик удачной загрузки файла
 */
export async function handleAddFiles(e, handleError = null, setPrecentages, handleSuccess = null) {

    e.preventDefault();
    e.stopPropagation();

    //Cначала обнуляем ошибку
    handleError?.(null);

    if (e.target.files) {
        const files = Array.from(e.target.files);

        if (files.length) {

            const uploadPromises = files.map(async (file) => {

                //Создаём объект, в котором будет хранится файл
                const data = new FormData();

                const uuid = getUUID();

                //Поле по которому передаётся файл
                data.append('file', file);

                const options = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let precent = Math.floor((loaded * 100) / total);

                        setPrecentages(p => {
                            const tmp = { ...p };
                            if (precent < 100) {
                                tmp[uuid] = precent;
                            } else {
                                delete tmp[uuid];
                            }

                            return tmp;
                        })
                    }
                };

                return UploadFile(data, options)
                    .then(url => {
                        handleSuccess(url);
                    })
                    .catch(e => handleError(e));
            });

            try {
                await Promise.all(uploadPromises);
                setTimeout(() => {
                    setPrecentages({});
                }, 1000)
            } catch (e) {
                setTimeout(() => {
                    setPrecentages({});
                }, 1000)
            }
        }
    }
}

