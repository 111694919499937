import { useContext, useEffect } from "react";
import PartnershipLoader from "../../../content_loader/partnership/PartnershipLoader";
import FlluppsSvg from "../../../../fllupps_svg/FlluppsSvg";
import useGetQuery from "../../../../custom_hooks/useGetQuery";
import useCancelToken from "../../../../custom_hooks/useCancelToken";
import { publicationKey } from "../../../../global-constants/request_keys";
import { getNewswareById } from "../../../../service/routes/newsware/newsware";
import { mainContext } from "../../../../App";

import Newsware from "../../../newsware/Newsware";

//Страница создания и редакатирования партнёрства
export default function PartnershipMode() {

    const { search, setSearch } = useContext(mainContext);

    const myNickname = localStorage.getItem('myNickname');

    //токен отмены запроса
    const { cancelToken } = useCancelToken();

    //Определение, если ли мод на редактирование или создание
    const mode = search.get('mode');

    //Параметры для запроса
    const params = {
        'newsware': search.get('newsware'),
        'type': search.get('type'),
        'username': myNickname
    };

    //Кастомный хук для запроса самого форума
    const { data: partnership, isFetching, remove } = useGetQuery({
        key: publicationKey,
        params: params,
        enabled: !!search.toString() && mode !== 'create', //Это нужно, чтобы при создании не подтягивался запрос
        request: getNewswareById,
        handleCatch: () => { setSearch({}) },
        cancelToken: cancelToken
    });

    useEffect(() => {
        return (() => {
            remove();
        })
    }, []);

    return (
        <div className="partnership-edit-box">
            <header className="partnership-edit-box_header">
                <FlluppsSvg onClick={() => setSearch({})} type='back_arrow' width={21} height={15} fill='none' />
                <p className="partnership-edit-box_header__caption">Партнёрство</p>
            </header>
            {
                isFetching ?
                    <PartnershipLoader />
                    :
                    <Newsware dataSet={{ ...partnership, type: 'partnership', full: true, mode: mode }} />
            }
        </div>
    )
}