import { useContext, createContext, useState, forwardRef, memo } from 'react';

import { Conversion } from '../../global_function/function';

import PostCard from './cards/post-card/PostCard';

import PreviewPartnersipCard from './cards/partnership-card/PreviewPartnersipCard';

import CaseCard from './cards/case-card/CaseCard';

import EventCard from './cards/event-card/EventCard';

import FullPartnershipCard from './cards/partnership-card/FullPartnershipCard';

import PreviewForumCard from './cards/forum-card/PreviewForumCard';

import FullForumCard from './cards/forum-card/FullForumCard';

import { NewswareIcons, Tags } from './components/newsware_components';

import { mainContext } from '../../App';

import _ from 'lodash';

export const NewswareContext = createContext();

const Newsware = forwardRef(({ dataSet = [] }, ref) => {
  const { page, goToComments } = useContext(mainContext);

  const {
    full = false,
    mode = null,
    time = new Date(),
    type = 'post',
    id = 0,
    stats = {
      likes: [],
      comments: [],
      favorites: [],
      reposts: [],
      views: [],
      useful: [],
    },
    tags = [],
  } = dataSet;

  //Стейты для статистики новостной ленты
  const [newswareStates, setNewswareStates] = useState({
    activeLike: false,
    activeFavorite: false,
    isUseful: false,
    clickUseful: false,
  });

  // let statsCount = {};

  // for (const key in stats) {
  //   statsCount[key] = Conversion('count', stats[key].length);
  // }

  //Изменение статистики новости
  const onClickIcon = (e) => {

    if (!e.target.getAttribute('name')) {
      return
    }

    switch (e.target.getAttribute('name')) {
      case 'like':
        alert('Лайкнул');
        break;
      case 'comment':
        goToComments(id, type);
        break;
      case 'repost':
        alert('Репостнул');
        break;
      case 'favorite':
        alert('Добавил в избранное')
        break;
      case 'useful':
        alert('Полезно')
        // setTimeout(() => {
        //   setNewswareStates({
        //     ...newswareStates,
        //     clickUseful: true,
        //   });
        // }, 500);
        break;
      default:
        return;
    }
  };

  return (
    <div ref={ref} className="newsware-box">
      {type === 'post' && <PostCard full={full} {...dataSet} />}
      {type === 'event' && <EventCard full={full} {...dataSet} />}
      {type === 'partnership' && (
        <>{full ? <FullPartnershipCard {...dataSet} /> : <PreviewPartnersipCard {...dataSet} />}</>
      )}
      {/* {type === 'case' && <CaseCard {...dataSet} />} */}
      {type === 'forum' && (
        <>{full ? <FullForumCard {...dataSet} /> : <PreviewForumCard {...dataSet} />}</>
      )}
      {full && !mode && (
        <>
          {tags.map((t, idx) => {
            return (
              <>
                {
                  !!t.value.length && (
                    <div key={`${t.name}-${idx}`} className="newsware-box_tags-box">
                      <p className="newsware-box_tags-box__category">{t.name}:</p>
                      {t.value.map((v, idx) => {
                        return (
                          <Tags
                            key={`${t.name}-${v}-${idx}`}
                            onClick={(e) => {
                              alert('А');
                            }}>
                            {v}
                          </Tags>
                        );
                      })}
                    </div>
                  )
                }
              </>
            );
          })}
        </>
      )}
      {((type !== 'partnership' || full) && type !== 'forum' && !mode) && (
        <div
          className='newsware-box_newsware-icons'
          onClick={onClickIcon}>
          <NewswareIcons
            full={full}
            newswareStates={newswareStates}
            setNewswareStates={setNewswareStates}
            stats={stats}></NewswareIcons>
        </div>
      )}
      {
        !mode && (
          <p className='newsware-box__time'>
            {Conversion('time', new Date(time), page)}
          </p>
        )
      }
    </div>
  );
});

export default memo(Newsware, (prev, cur) => _.isEqual(prev, cur));
