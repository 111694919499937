import Newsware from './Newsware';
import NewswareLoader from '../content_loader/newsware/NewswareLoader';
import EventFilter from '../event_filter/EventFilter';
import { memo, useEffect } from 'react';
import useCancelToken from '../../custom_hooks/useCancelToken';
import useGetQuery from '../../custom_hooks/useGetQuery';
import { newswareAllKey } from '../../global-constants/request_keys';
import { getNewsware } from '../../service/routes/newsware/newsware';
import { useInView } from 'react-intersection-observer';
import { PaginationLoader } from '../loaders/loaders';
import { generateCategoryQuery } from '../../global_function/function';
import _ from 'lodash';

//Компонент, который отрисовывает список новостной ленты, будь, то пост, мероприятие или партнёрство
/**
 * 
 * @param {DOMElement} children //Нужен для показа, когда новостная лента пуста
 * @param {object | string} params //Параметры для get запросов
 * @param {Function} setParams //Функция изменения гет параметров, больше для фильтра тегов
 * @param {Array} newsware //Массив новостной ленты из вне 
 * @param {Function} setNewsware //Функция изменения этого массива
 * @param {Boolean} filterVisible //Нужно ли показывать EventFilter
 * @param {Array} filterTable //Описываются категории фильтрации под разные случаи, у партнёрств свои, у мероприятий свои и т.д.
 */
function NewswareItems({
    children,
    params = null,
    setParams = () => { return; },
    newsware = [],
    setNewsware = () => { return; },
    filterVisible = false,
    filterTable = []
}) {

    //Токен отмены запроса
    const { cancelToken } = useCancelToken([JSON.stringify(params)]);

    //Хук для гет запроса
    const { data, isFetching, remove } = useGetQuery({
        key: newswareAllKey,
        params: params,
        request: getNewsware,
        cancelToken: cancelToken,
        onError: () => {return;}
    });

    //Фильтрация по категориям
    function handleCategoryClick(tags) {
        const tmp = generateCategoryQuery(tags);
        setNewsware([]);
        setParams({
            ...params,
            page: 1,
            ...tmp
        })
    };

    //Вью для пагинации
    const { ref, inView } = useInView({
        threshold: 0.5
    });

    useEffect(() => {
        if (data?.length && inView && data?.length > 3) {
            setParams({
                ...params,
                page: params.page + 1
            })
        }
    }, [inView]);

    useEffect(() => {
        if (data?.length) {
            setNewsware(n => {
                const tmp = [...n];
                return [...tmp, ...data]
            })
        }
    }, [JSON.stringify(data)]);

    useEffect(() => {
        return () => {
            setNewsware([]);
            setParams({});
            remove();
        }
    }, [])

    return (
        <>
            {filterVisible && <EventFilter table={filterTable} setState={handleCategoryClick} className='newsware-filter' />}
            <main className="main-background">
                {
                    isFetching && params.page === 1 ?
                        <>
                            {
                                [...new Array(3)].map((_, idx) => {
                                    return (
                                        <div key={`newsware-${idx}`} className="newsware-box">
                                            <NewswareLoader />
                                        </div>
                                    )
                                })
                            }
                        </>
                        :
                        <>
                            {
                                !!newsware.length ?
                                    <>
                                        {
                                            newsware
                                                .map((d, idx) => {
                                                    return (
                                                        idx === newsware.length ?
                                                            <Newsware key={`newsware-${d.id}`} dataSet={d} />
                                                            :
                                                            <Newsware ref={ref} key={`newsware-${d.id}`} dataSet={d} />
                                                    )
                                                })
                                        }
                                    </>
                                    :
                                    <>
                                        {children}
                                    </>
                            }
                        </>
                }
            </main>
            {
                isFetching && params.page > 1 && <PaginationLoader />
            }
        </>

    )
}

export default memo(NewswareItems, (prev, next) =>
    _.isEqual(prev.params, next.params)
    && _.isEqual(_.omit(prev.setParams, _.functions(prev.setParams)), _.omit(next.setParams, _.functions(next.setParams)))
    && _.isEqual(prev.newsware, next.newsware)
    && _.isEqual(_.omit(prev.setNewsware, _.functions(prev.setNewsware)), _.omit(next.setNewsware, _.functions(next.setNewsware)))
    && prev.filterVisible === next.filterVisible
    && _.isEqual(prev.filterTable, next.filterTable)
)