import FlluppsApi from "../../api/main_api";

import { getQueryParams } from "../../helpers";


//Функция получения элемента новостной ленты по id
export function getNewswareById(params = null, cancelToken = null) {
    return FlluppsApi.get(`/publications/one_by_id?${getQueryParams(params)}`, { cancelToken: cancelToken });
};

//Функция создания элемента новостной ленты
export function createNewsware(data, cancelToken = null) {
    return FlluppsApi.post('/publications/add', JSON.stringify(data), { cancelToken: cancelToken });
}

//Функция обновления элемента новостной ленты
export function updateNewsware(data, cancelToken = null) {
    return FlluppsApi.put('/publications/update', JSON.stringify(data), { cancelToken: cancelToken });
}

//Функция удаления элемента новостной ленты
export function deleteNewsware(newsware_id = '', cancelToken = null){
    return FlluppsApi.delete(`/publications/del?newsware=${newsware_id}`, { cancelToken: cancelToken });
}

//Функция отображения новостной ленты
export function getNewsware(params = null, cancelToken = null) {
    return FlluppsApi.get(`/publications?${getQueryParams(params)}`, cancelToken ? { cancelToken: cancelToken } : {});
}