import { useState, useContext } from 'react';
import { mainContext } from '../../App';
import { HeaderFilter, Header } from './components/home-components';
import NewswareItems from '../../global_components/newsware/NewswareItems';
import NoFoundContent from '../../global_components/no_found_content/NoFoundContent';

const Home = () => {
    const {
        isNewswareFilterOpen,
        setIsNewswareFilterOpen } = useContext(mainContext);

    //Стейт url параметров для фильтрации
    const [params, setParams] = useState({ page: 1 });

    //Стейт списка новостной ленты
    const [newsware, setNewsware] = useState([]);

    //Функция нажатия на фильтр
    async function clickHeaderFilter(e) {
        if (e.target.tagName === 'LI') {
            if (e.target.getAttribute('name') !== params?.type) {
                setNewsware([]);
                setParams({
                    page: 1,
                    type: e.target.getAttribute('name') === 'all' ? undefined : e.target.getAttribute('name')
                });
            }
            setIsNewswareFilterOpen();
        }
    };

    return (
        <>
            <HeaderFilter onClick={clickHeaderFilter} className={isNewswareFilterOpen ? 'filter-newsware-box open' : 'filter-newsware-box'}></HeaderFilter>
            <Header onClick={setIsNewswareFilterOpen} className={isNewswareFilterOpen ? 'header-box_logo-box_arrow-box open' : 'header-box_logo-box_arrow-box'}></Header>
            <NewswareItems
                params={params}
                setParams={setParams}
                newsware={newsware}
                setNewsware={setNewsware}
            >
                <NoFoundContent>Нет ни одной новости</NoFoundContent>
            </NewswareItems>

        </>
    )

};

export default Home;
