import { NavigationFooter, TextFooter } from "./components/components";
import { mainContext } from "../../App";

import { memo, useCallback, useContext } from "react";

function Footer() {

    const { messageText, setMessageText } = useContext(mainContext);

    const handleChange = useCallback((e) => {
        setMessageText(e.target.value);
    }, [messageText]);

    return (
        <footer className="footer-container">
            <NavigationFooter/>
            {/* <TextFooter handleChange={handleChange} value={messageText} /> */}
        </footer>
    )
}

export default memo(Footer);