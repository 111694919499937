import { useState } from "react";
import { listColorClick, getUUID, handleAddFiles } from "../../../../../global_function/function";
import { SelectSearch, ValidationInput, Expandingarea, FlluppsLinkText } from "../../../../../custom_tags/custom_tags";
import FlluppsSvg from "../../../../../fllupps_svg/FlluppsSvg";

//Компонент статуса
export function ForumStatus({ code, value = 'открыто', onChange, mode = null }) {

    //Стейт открытия и закрытия редактирования статуса
    const [open, setOpen] = useState(false)

    //Функция выбора статуса
    function handleClick(e) {
        if (e.target.tagName === "LI") {
            setOpen(false);
            onChange(code, e.target.getAttribute('name'));
        }
    }

    //Здесь ещё нужно будет добавить проверку, что есть лучший ответ
    return (
        <>
            <p className="full-forum-box__status">
                Статус: {value}
                {
                    mode === 'edit' && <span onClick={() => setOpen(!open)} className={`full-forum-box__status--choose-triangle ${open ? "open" : ""}`} />
                }
            </p>
            {
                mode === 'edit' && open && (
                    <ul
                        onClick={handleClick}
                        className="full-forum-box__status_choose-list"
                        onTouchStart={(e) => listColorClick(e.target)}
                        onMouseDown={(e) => listColorClick(e.target)}
                        onTouchEnd={(e) => listColorClick(e.target)}
                        onMouseUp={(e) => listColorClick(e.target)}
                    >
                        <li name="opened" className="full-forum-box__status_choose-list__item">Открыто</li>
                        <li name='closed' className="full-forum-box__status_choose-list__item">Закрыто</li>
                    </ul>
                )
            }
        </>
    )
}

//Компонент, который оборачивает в fieldset
export function FieldSetBox({ code = null, label = null, children }) {
    return (
        <fieldset name={code} className="full-forum-box_form-field">
            <p className="full-forum-box_form-field__label">{label}</p>
            {children}
        </fieldset>
    )
}

//Компонент с названием проекта и его редактировании
export function ForumName({ value = '', onChange, mode = null }) {
    return (
        <>
            {
                ['create', 'edit'].includes(mode) ?
                    (
                        <FieldSetBox code='name' label="Тема обсуждения/проблема">
                            <ValidationInput name='name' value={value} onChange={onChange} type="text" className="full-forum-box_form-field__input" />
                        </FieldSetBox>
                    )
                    :
                    (
                        <h1 className="full-forum-box__name">{value}</h1>
                    )
            }
        </>
    )
}

//Компонент добавления контента
export function AddContent({ setState, setPrecentages }) {

    const [open, setOpen] = useState(false);

    const [error, setError] = useState(null);

    function handleOpen(e) {
        e.stopPropagation();
        e.preventDefault();

        setOpen(!open);
    };

    //Функция добавления текста в медиа
    function handleAddText() {
        setState(f => {
            const tmp = { ...f }
            return {
                ...tmp,
                media: [...tmp.media, { uuid: getUUID(), type: 'text', content: { text: '' } }]
            }
        })
    }


    return (
        <div className="full-forum-box_add-content-box">
            <button onClick={handleOpen} className="full-forum-box_add-content-box__btn">+Добавить контент</button>
            {
                open && (
                    <ul
                        onTouchStart={(e) => listColorClick(e.target)}
                        onMouseDown={(e) => listColorClick(e.target)}
                        onTouchEnd={(e) => listColorClick(e.target)}
                        onMouseUp={(e) => listColorClick(e.target)}
                        className="full-forum-box_add-content-box_list"
                    >
                        <li onClick={handleAddText}>Добавить текст</li>
                        <li>
                            <div className="full-forum-box_add-content-box_list__add-media-box">
                                <input
                                    id='forumMedia'
                                    multiple
                                    onChange={(e) => handleAddFiles(e, setError, setPrecentages, (url) => {
                                        setState((d) => {
                                            return {
                                                ...d,
                                                media: [...d.media, { uuid: getUUID(), type: 'image', content: url }]
                                            }
                                        });
                                    })}
                                    type='file'
                                    accept="image/*" />
                                <label htmlFor="forumMedia" className="full-forum-box_add-content-box_list__add-media-box--label">Добавить медиа</label>
                            </div>
                        </li>
                    </ul>
                )
            }
            {error && <p className="full-forum-box_add-content-box__error">{error}</p>}
        </div>
    )
}

//Компонент с выбором города
export function SelectCityFieldset({ children, dataSet = [], value = '', code, onChange }) {
    return (
        <FieldSetBox code={code} label={children}>
            <SelectSearch
                boxClassName="full-forum-box_form-field_select-box"
                inputClassName='full-forum-box_form-field_select-box__input'
                value={value}
                dataSet={dataSet}
                setValue={(value) => onChange(code, value)}
            />
        </FieldSetBox>
    )
}

//Компонент с текстом
export function ForumTextField({ mode = null, uuid = null, onChange, text = '', onDelete }) {
    return (
        <>
            {
                ['create', 'edit'].includes(mode) ?
                    <fieldset className="full-forum-box_form-field-text">
                        <FlluppsSvg onClick={() => onDelete(uuid)} className="full-forum-box_form-field-text__close" type="close" width={14} height={14} fill="none" />
                        <Expandingarea value={text} onChange={(e) => onChange(e, uuid)} placeholder="Расскажите подробнее" className="full-forum-box_form-field-text__text-area" isRequired />
                    </fieldset>
                    :
                    <FlluppsLinkText className="full-forum-box__text">{text}</FlluppsLinkText>
            }
        </>
    )
}
