//Функция для раскладывания object в query параметры
export function getQueryParams(params = {}) {
    let query = '';
    let idx = 0;
    if (params) {
        for (let key in params) {
            if (!!params[key]) {
                query += idx > 0 ? `&${key}=${params[key]}` : `${key}=${params[key]}`;
                idx++;
            }
        }
    }

    return query;
}