import {
    PartnershipName,
    PartnershipStatus,
    ParnershipPartners,
    ParnershipFieldset,
    PartnershipMedia,
    PrevPartnerships,
    SelectCityFieldset
} from "./components/components"
import { useContext, useState } from "react";
import { mainContext } from "../../../../App";
import { createNewsware, updateNewsware, deleteNewsware } from "../../../../service/routes/newsware/newsware";
import { createPartnershipKey, updatePartnershipKey, deletePartnershipKey } from "../../../../global-constants/request_keys";
import { BtnLoader } from "../../../loaders/loaders";
import EventFilter from "../../../event_filter/EventFilter";
import { partnershipDataSet } from "../../../../global-constants/constants";
import { getArrToObj } from "../../../../global_function/function";
import { Flluppsimage } from "../../../../custom_tags/custom_tags";
import FlluppsSvg from "../../../../fllupps_svg/FlluppsSvg";
import russian_cities from '../../../../data/russian_cities.json';
import useUpdate from "../../../../custom_hooks/useUpdate";
import useCancelToken from "../../../../custom_hooks/useCancelToken";
import { listColorClick } from "../../../../global_function/function";


export default function FullPartnershipCard(newsware) {
    //Разбераем объект
    const {
        id = 0,
        type = 'partnership',
        mode = null,
        author_nickname = null,
        author_avatar = null,
        geoposition = null,
        status = 'opened',
        name = null,
        media = [],
        idea = null,
        partners = [],
        want_to_find = null,
        budget = null,
        current_result = null,
        resolved = null,
        prev_partnerships = [],
        tags = []
    } = newsware;

    const { navigate, location, openModal, goToComments, setSearch } = useContext(mainContext);

    //Токен отмены запроса
    const { cancelToken } = useCancelToken();

    //Мой никнейм
    const myNickname = localStorage.getItem('myNickname');

    //Определяем state, нужен для создания и отображения
    const [partenrshipState, setPartnershipState] = useState({
        status: status,
        name: name || '',
        media: media ? [...media] : [],
        idea: idea || '',
        partners: partners ? [...partners] : [],
        want_to_find: want_to_find || '',
        budget: budget || '',
        current_result: current_result || '',
        resolved: resolved || '',
        geoposition: geoposition
    });

    //Стейт для выбора действий
    const [openChoose, setOpenChoose] = useState(false);

    //Стейт для ошибки
    const [error, setError] = useState(null);

    //Описываем запрос создания и обновления
    const partnershipCreate = useUpdate({
        key: createPartnershipKey,
        request: createNewsware,
        cancelToken: cancelToken,
        handleSuccess: (d) => {
            goToComments(d, 'partnership')
        },
        handleCatch: (e) => {
            setError(e);
        }
    });

    const partnershipUpdate = useUpdate({
        key: updatePartnershipKey,
        request: updateNewsware,
        cancelToken: cancelToken,
        handleSuccess: (d) => {
            goToComments(d, 'partnership')
        },
        handleCatch: (e) => {
            setError(e);
        }
    });

    //Описываем запрос удаления
    const partnershipDelete = useUpdate({
        key: deletePartnershipKey,
        params: id,
        request: deleteNewsware,
        cancelToken: cancelToken,
        handleSuccess: () => {setSearch({})},
        handleCatch: (e) => {
            setError(e)
        }
    })

    //Определяем стейт для редактирования
    const [updateState, setUpdateState] = useState({});

    //Теги фильтра
    const [filter, setFilter] = useState(getArrToObj(tags ? tags : []));

    //Функция преобразование кода статуса в текст
    function handleCodeStatusText(code = 'opened') {
        switch (code) {
            case 'opended':
                return 'открыто'
            case 'in_process':
                return 'в процессе'
            case 'closed':
                return 'закрыто'
            default:
                return 'открыто'
        }
    }

    //Функция обновления полей
    function handleChange(code, value) {
        //Для создания
        setPartnershipState((p) => {
            const tmp = { ...p };
            return {
                ...tmp,
                [code]: value
            }
        });

        //Для обновления
        setUpdateState(u => {
            const tmp = { ...u };
            if (value !== newsware[code]) {
                tmp[code] = value;
            }
            return tmp;
        })
    }

    //Функция на создание и обновление партнёртсва
    function handleSubmit(e, type) {
        e.stopPropagation();
        e.preventDefault();

        if (type === 'create') {
            handleCreate();
        };

        if (type === 'update') {
            handleUpdate();
        };
    }

    //Функция создания
    function handleCreate() {
        const data = {
            username: myNickname,
            type: 'partnership',
            geoposition: partenrshipState.geoposition,
            tags: [{
                code: 'durations',
                name: 'Длительность',
                value: filter['durations']
            },
            {
                code: 'formats',
                name: 'Формат',
                value: filter['formats']
            },
            {
                code: 'tags',
                name: 'Направление',
                value: filter['tags']
            },
            {
                code: 'types',
                name: 'Тип',
                value: filter['types']
            },
            {
                code: 'cities',
                name: 'Город',
                value: filter['cities']
            }],
            ...partenrshipState
        }

        partnershipCreate.mutate(data);
    }

    //Функция редактирования
    function handleUpdate() {
        const data = {
            ...updateState,
            media: [...partenrshipState.media],
            username: myNickname,
            type: 'partnership',
            tags: [{
                code: 'durations',
                name: 'Длительность',
                value: filter['durations']
            },
            {
                code: 'formats',
                name: 'Формат',
                value: filter['formats']
            },
            {
                code: 'tags',
                name: 'Направление',
                value: filter['tags']
            },
            {
                code: 'types',
                name: 'Тип',
                value: filter['types']
            },
            {
                code: 'cities',
                name: 'Город',
                value: filter['cities']
            }],
            id: id
        };

        partnershipUpdate.mutate(data);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="full-partnership-header-box">
                <div className="full-partnership-header-box_author-box">
                    <Flluppsimage className="full-partnership-header-box_author-box_avatar-box" src={author_avatar} alt='avatar' />
                    <div className="full-partnership-header-box_author-box_sing-box">
                        <p className="full-partnership-header-box_author-box_sing-box__nickname">{author_nickname}</p>
                        <p className="full-partnership-header-box_author-box_sing-box__geolocation">
                            <FlluppsSvg type="geoposition" style={{ marginRight: '2px' }} width={8} height={11} fill="none" />
                            {partenrshipState.geoposition || 'Не указано'}
                        </p>
                    </div>
                </div>
                {
                    partnershipCreate.isLoading || partnershipUpdate.isLoading || partnershipDelete.isLoading ?
                        (
                            <BtnLoader />
                        )
                        :
                        (
                            <div className="full-partnership-header-box_btn-box">
                                {
                                    myNickname === author_nickname && !mode && (
                                        <>
                                            <div onClick={() => setOpenChoose(!openChoose)} className="full-partnership-header-box_btn-box_ellipsis-box">
                                                <span className="full-partnership-header-box_btn-box_ellipsis-box--ellipse" />
                                                <span className="full-partnership-header-box_btn-box_ellipsis-box--ellipse" />
                                                <span className="full-partnership-header-box_btn-box_ellipsis-box--ellipse" />
                                            </div>
                                            {
                                                openChoose && (
                                                    <ul
                                                        className="full-partnership-header-box_btn-box_btn-list"
                                                        onTouchStart={(e) => listColorClick(e.target)}
                                                        onMouseDown={(e) => listColorClick(e.target)}
                                                        onTouchEnd={(e) => listColorClick(e.target)}
                                                        onMouseUp={(e) => listColorClick(e.target)}
                                                    >
                                                        <li onClick={() => { navigate(`${location.pathname}?newsware=${id}&type=${type}&mode=edit`); openModal('partnership') }}>
                                                            <FlluppsSvg type="pencil" width={15} height={15} fill="none" />
                                                            Редактировать
                                                        </li>
                                                        <li onClick={() => { setOpenChoose(false); partnershipDelete.mutate() }}>
                                                            <FlluppsSvg type="basket" width={15} height={15} fill="none" />
                                                            Удалить
                                                        </li>
                                                    </ul>
                                                )
                                            }
                                        </>
                                    )
                                }
                                {
                                    mode === 'create'
                                    && <input type="submit" onClick={(e) => handleSubmit(e, 'create')} value="Создать" className="full-partnership-header-box_btn-box__btn" />
                                }
                                {
                                    mode === 'edit'
                                    && myNickname === author_nickname
                                    && <input type="submit" onClick={(e) => handleSubmit(e, 'update')} value="Готово" className="full-partnership-header-box_btn-box__btn" />
                                }
                                {
                                    error && <p className="full-partnership-header-box_btn-box--error">{error}</p>
                                }
                            </div>
                        )
                }
            </div>
            <div className="full-partnership-box">
                <PartnershipStatus code='status' value={handleCodeStatusText(partenrshipState.status)} mode={mode} onChange={(code, value) => handleChange(code, value)} />
                {
                    ['create', 'edit'].includes(mode) &&
                    <EventFilter table={partnershipDataSet} state={filter} setState={setFilter} />
                }
                <PartnershipName value={partenrshipState.name} mode={mode} onChange={(e) => handleChange(e.target.name, e.target.value)} />
                <ParnershipPartners onClick={() => alert('АГА')} partners={partners} mode={mode} />
                {
                    ['create', 'edit'].includes(mode) &&
                    <SelectCityFieldset value={partenrshipState.geoposition} code="geoposition" onChange={(code, value) => handleChange(code, value)} dataSet={[...russian_cities.map(c => c.name)]}>Выберите город:</SelectCityFieldset>
                }
                <ParnershipFieldset value={partenrshipState.idea} onChange={(code, value) => handleChange(code, value)} mode={mode} code="idea">Что предлагаю:</ParnershipFieldset>
                {
                    partenrshipState.status === 'closed' &&
                    <ParnershipFieldset value={partenrshipState.resolved} onChange={(code, value) => handleChange(code, value)} mode={mode} code="resolved">Результат партнёрства:</ParnershipFieldset>
                }
                {
                    ['opened', 'in_process'].includes(partenrshipState.status) &&
                    <ParnershipFieldset value={partenrshipState.want_to_find} onChange={(code, value) => handleChange(code, value)} mode={mode} code="want_to_find">Кого ищу:</ParnershipFieldset>
                }
                <ParnershipFieldset value={partenrshipState.budget} onChange={(code, value) => handleChange(code, value)} mode={mode} code="budget">Бюджет:</ParnershipFieldset>
                {
                    partenrshipState.status === 'in_process' &&
                    <ParnershipFieldset value={partenrshipState.current_result} onChange={(code, value) => handleChange(code, value)} mode={mode} code="current_result">Текущий результат:</ParnershipFieldset>
                }
                <PartnershipMedia setUpdateState={setUpdateState} setPartnershipState={setPartnershipState} mode={mode} media={partenrshipState.media} />
                {/* <PrevPartnerships prev_partnerships={prev_partnerships} /> */}
            </div>
        </form>
    )
}