import { useQuery } from "react-query";

/**
 * Хук необходимый для всех GET запросов, включая пагинацию и параметры запроса
 * @param {string} key ключ запрос
 * @param {object | string} params параметры запроса
 * @param {Function} request функция запроса
 * @param {number} retry кол-во попыток запроса
 * @param {Function} handleCatch обработчик ошибки, если нужен
 * @param {Function} handleFinnaly обработчик всплывающий всегда, если нужен
 * @param {AxiosToken} cancelToken токен отмены запроса
 * @param {Boolean} enabled необходимо для последовательных запросов
 */

export default function useGetQuery({ key = '', enabled = true, params = {}, request = null, retry = 0, handleCatch = null, handleFinnaly = null, cancelToken = null }) {

    //Функция отправки запроса
    async function fetchData(params) {

        if (params) {
            return await request(params, cancelToken);
        }

        return await request(cancelToken);
    }

    const { data, isFetching, isError, error, refetch, remove } = useQuery({
        queryKey: [key, JSON.stringify(params)],
        queryFn: async () => await fetchData(params),
        select: data => data?.data || data,
        retry: retry,
        cacheTime: 60000,
        staleTime: 30000,
        enabled: enabled,
        onError: (error) => {
            handleCatch?.(error);
        },
        onSettled: (data, error) => {
            handleFinnaly?.(data, error);
        },
        refetchOnMount: false,
        refetchOnWindowFocus: false, // Не обновлять данные при фокусе окна
    });

    return ({
        data,
        isFetching,
        isError,
        error,
        refetch,
        remove
    });
}