import { Skeleton } from "../../../custom_tags/custom_tags"

export default function PublicationLoader(){
    return (
        <div className="publication-skeleton">
            <Skeleton className="publication-skeleton__avatar"/>
            <Skeleton className="publication-skeleton__name"/>
            <Skeleton className="publication-skeleton__geoposition"/>
            <Skeleton className="publication-skeleton__text"/>
            <Skeleton className="publication-skeleton__image"/>
            <Skeleton className="publication-skeleton__text"/>
            <Skeleton className="publication-skeleton__image"/>
            <Skeleton className="publication-skeleton__tags"/>
            <Skeleton className="publication-skeleton__tags"/>
            <Skeleton className="publication-skeleton__tags"/>
            <Skeleton className="publication-skeleton__tags"/>
        </div>
    )
}