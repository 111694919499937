import React, { memo, useEffect, useRef, useState } from 'react';
import { Splide, SplideTrack } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { PrevArrow, NextArrow, ProgressBar } from './components/components';

//Компонент со слайдером медиа
/**
 * @param {string} sliderClassName Стили для контейнера(позиционирование и размер) !!!Важно высоту не указывать, высота указывается у контента
 * @param {children} Сами слайд ВАЖНО ОТМЕТИТЬ, ЧТО У КОНТЕНТА НЕ НУЖНО ЗАДАВАТЬ width!!! 
 */
function FlluppsSlider({ children, sliderClassName = '' }) {

    //Количество всех слайдов
    const total = Array.from(children)?.length;

    //Реф на сам слайдер
    const sliderRef = useRef(null);

    //Пролистано слайдов в процентах
    const [progress, setProgress] = useState((100 / total).toFixed(4));


    useEffect(() => {
        if (sliderRef.current) {
            const splideInstance = sliderRef.current.splide;

            splideInstance.on('move', (idx) => {

                const busy = (idx + 1) * (100 / total).toFixed(4);

                setProgress(busy);

            });

            return () => {
                if (splideInstance) {
                    splideInstance.destroy();
                }
            };
        }
    }, []);

    function handleNext() {
        if (sliderRef.current) {
            const splideInstance = sliderRef.current.splide;
            splideInstance.go(">");
        }
    }

    function handlePrev() {
        if (sliderRef.current) {
            const splideInstance = sliderRef.current.splide;
            splideInstance.go("<");
        }
    }



    return (
        <>
            <div className={`fllupps-slider-container ${sliderClassName}`}>
                {
                    total > 1 && (
                        <>
                            <PrevArrow onClick={handlePrev} />
                            <NextArrow onClick={handleNext} />
                        </>
                    )
                }
                <Splide
                    ref={sliderRef}
                    options={{
                        type: 'slide',
                        gap: "10px",
                        padding: '20px',
                        rewind: false,
                        speed: 200,
                        perPage: 1,
                        pagination: false,
                        fixedWidth: '100%',
                        fixedHeight: '100%',
                        arrows: false,
                        start: 0,
                    }}
                    hasTrack={false}>
                    <SplideTrack>
                        {children}
                        {/* {media.map((m, idx) => {
                            return (
                                <SplideSlide key={`full-partneship-media-${idx}`}>
                                    <img
                                        style={{
                                            width: "100%",
                                            height: "400px"
                                        }}
                                        src={m.content}
                                        alt="media"
                                    />
                                </SplideSlide>
                            );
                        })} */}
                    </SplideTrack>
                    {
                        total > 1 && <ProgressBar progress={progress} />
                    }
                </Splide>
            </div>
        </>
    )
}

export default memo(FlluppsSlider, (prev, next) => prev.sliderClassName === next.sliderClassName);