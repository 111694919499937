import axios, {isCancel} from "axios";

const FlluppsApi = axios.create({
    baseURL: process.env.REACT_APP_MAIN_BACKEND_URL,
    timeout: 30000,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow': 'Origin, X-Requested-With, Content-Type, Accept'
    }
});

//Интерцепторы
FlluppsApi.interceptors.request.use(
    (config) => {
        // Получаем токен
        const token = localStorage.getItem('token'); //Получение токена из loacalStorage;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

FlluppsApi.interceptors.response.use((res) => {
    if(res.headers["authorization"]){
        localStorage.setItem("token", res.headers["authorization"].replace("Bearer ", ""));
    }
    return Promise.resolve(res.data || res);
},
    (error) => {
        if(isCancel(error)){
            return Promise.reject('Обращение отменено.');
        }
        if (error.response?.status === 401) {
            window.location.pathname = '/login';
        }
        return Promise.reject(error.response?.data || error.message);
    }
);

export default FlluppsApi;