import defaultAvatar from '../img/defaultAvatar.png';

import interest_data from '../data/interest_data.json';
import russian_cities from '../data/russian_cities.json';

export const defaultUser = {
    userId: 0,
    avatar: defaultAvatar,
    background:
        'https://phonoteka.org/uploads/posts/2021-04/thumbs/1618498711_49-phonoteka_org-p-fioletovii-fon-v-stime-50.png',
    nickname: 'Undefind_Star',
    firstName: 'Потерянная',
    lastName: 'Звёздочка',
    subscribers: [],
    subscriptions: [],
    viewUsers: [],
    role: 'Неизвестна',
    softSkills: [],
    status: 'offline',
    idea: 'Найтись в этом мире',
    who: 'professional',
    achievements: [],
    notification: 'false',
    interests: [],
    lastTime: '',
    tabsName: ['Все чаты'],
};

export const defaultPost = {
    postId: 0,
    type: 'post',
    authorId: 0,
    stats: {
        likes: [],
        comments: [],
        favorites: [],
        reposts: [],
        views: [],
    },
    imgs: ['https://fikiwiki.com/uploads/posts/2022-02/1645041619_8-fikiwiki-com-p-ya-v-shoke-prikolnie-kartinki-9.jpg'],
    signImgs: ['Пост не найден :('],
    geoposition: '',
    time: '',
};

export const defaultChat = {
    chatId: 0,
    companionsId: [],
    tabsName: [],
    conversationName: "",
    conversationAvatar: "",
    fullStatus: '',
    messages: [],
};

export const defaultComment = {
    commentId: 0,
    authorCommentId: 0,
    text: 'Комментарий не найден :(',
    likes: [],
    replies: [],
    time: '',
    isReply: false,
};

//Датасет из категорий для фильтра мероприятий
export const eventDataSet = [
    {
        code: 'tags',
        name: 'Направление',
        value: interest_data.map(i => i.name)
    },
    {
        code: 'forms',
        name: 'Форма участия',
        value: ['Платная', 'Бесплатная', 'Волонтерство']
    },
    {
        code: 'formats',
        name: 'Формат',
        value: ['Онлайн', 'Офлайн']
    },
    {
        code: 'cities',
        name: 'Город',
        value: russian_cities.map(c => c.name)
    }
]

//Датасет из категорий для фильтра партнёрства
export const partnershipDataSet = [
    {
        code: 'durations',
        name: 'Длительность',
        value: ['Долгосрочно', 'Краткосрочно']
    },
    {
        code: 'formats',
        name: 'Формат',
        value: ['Студия', 'Магазин', 'Агенство', 'Фестиваль', 'Ярмарка', 'Выставка', 'Театр', 'Кинотеатр', 'Курс', 'Кафе', 'Концерт', 'Сайт', 'Акция']
    },
    {
        code: 'tags',
        name: 'Направление',
        value: interest_data.map(i => i.name)
    },
    {
        code: 'types',
        name: 'Тип',
        value: ['Коммерческий', 'Некоммерческий']
    },
    {
        code: 'cities',
        name: 'Город',
        value: russian_cities.map(c => c.name)
    }
]

//Датасет из категорий для фильтра форума
export const forumDataSet = [
    {
        code: 'tags',
        name: 'Направление',
        value: interest_data.map(i => i.name)
    }
]

//Датасет для кнопок выбора статуса в партнёрствах
export const partenrshipBtns = [
    {
        code: 'opened',
        name: 'Открыты',
        count: 0
    },
    {
        code: 'in_process',
        name: 'В процессе',
        count: 0
    },
    {
        code: 'closed',
        name: 'Закрыты',
        count: 0
    },
    {
        code: 'me',
        name: 'Мои',
        count: 0
    },
]

//Датасет для кнопок выбора статуса в форумах
export const forumBtns = [
    {
        code: 'opened',
        name: 'Открыты',
        count: 0
    },
    {
        code: 'closed',
        name: 'Закрыты',
        count: 0
    },
    {
        code: 'me',
        name: 'Мои',
        count: 0
    },
]

//Константа для начальной страницы проектов
//(Когда все разделы будут активны поле disabled будет не нужно)
export const projects = [
    {
        name: 'Партнёрства',
        disabled: false,
        caption: 'Здесь вы можете рассказать о своей идее и найти единомышленников для ее реализации.',
        navigateTo: '/partnerships'
    },
    {
        name: 'Фриланс-проекты',
        disabled: true,
        caption: 'Здесь вы можете найти интересные проекты от заказчиков по разным направлениям.',
        navigateTo: '/'
    },
    {
        name: 'Вакансии',
        disabled: true,
        caption: 'Здесь вы можете увидеть вакансии от компаний, которые ищут крутых специалистов.',
        navigateTo: '/'
    },
    {
        name: 'Краудфандинг',
        disabled: true,
        caption: 'Здесь вы можете поделиться своей идеей и найти финансирование для ее реализации.',
        navigateTo: '/'
    }
];