
import { useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { reducer } from '../reducer';

import { defaultUser } from '../global-constants/constants';

export function GlobalStates() {

    const location = useLocation();
    const navigate = useNavigate();

    //Глобальные стейты приложения
    const appState = {
        userId: null,
        userName: null,
        userPhoto: null,
        isNewswareFilterOpen: false,
        modalOption: null,
        footType: 'navigation',
        page: 'login',
        messageText: '',
        activeGlobalSearch: localStorage.getItem('activeGlobalSearch') ? JSON.parse(localStorage.getItem('activeGlobalSearch')) : 'globalCase'
    };

    //Создание Рудюсера(оброботчика всех стейтов)
    const [appvalue, dispatch] = useReducer(reducer, appState);

    //Функция для изменения стейтов

    //Открытие модального окна
    appvalue.openModal = (option = null) => {
        document.body.style.overflowY = 'hidden';
        dispatch({ type: 'SET_MODAL', payload: { option: option } });
    }

    //Закрытие модального окна
    appvalue.closeModal = () => {
        document.body.style.overflowY = 'auto';
        dispatch({ type: 'SET_MODAL', payload: { option: null } });
    }

    //Открытие и закрытие фильтра на главной странице
    appvalue.setIsNewswareFilterOpen = () => {
        dispatch({ type: 'SET_NEWSWARE_FILTER_OPEN' });
    };

    //Функция перехода в Profile
    appvalue.goToProfile = (userProfile = defaultUser) => {
        navigate(`/profile/${userProfile.nickname}`);
    }

    //Переход на страницу комментариев
    appvalue.goToComments = (newswareId = 0, type = 'post') => {
        navigate(location.pathname + `?newsware=${newswareId}&type=${type}`);
    };

    //Возвращение на страницу назад
    appvalue.goBack = () => {
        navigate(-1);
    };

    //Запролнение стейта сообщения или комментария
    appvalue.setMessageText = (text = '') => {
        dispatch({ type: 'SET_MESSAGE_TEXT', payload: text });
    };

    //Заполение информации о пользователи
    appvalue.setUserProfile = (nickname = "Fllupper", photo = null) => {
        dispatch({ type: 'SET_USER_PROFILE', payload: { nickname: nickname, photo: photo } })
    }

    appvalue.goHome = () => {
        navigate('/home');
    };

    appvalue.setFooterType = (type = 'navigation') => {
        dispatch({ type: 'SET_FOOTER_TYPE', payload: type })
    };

    return {
        dispatch,
        appvalue
    }


}
