import React, { useState } from 'react';

import FlluppsSvg from '../../../fllupps_svg/FlluppsSvg';

import { ValidationInput } from '../../../custom_tags/custom_tags';

//Кнопка отправки формы
export const SubmitBtn = ({ children }) => {
  return (
    <input
      value={children}
      type="submit"
      className="authorisation-main-container_form-box__form-btn"
    />
  );
};

//Поля для ввода текста
export const AuthInput = ({ placeholder, onChange, onFocus, className, name, value = '' }) => {
  return (
    <ValidationInput
      value={value}
      name={name}
      onChange={onChange}
      onFocus={onFocus}
      type="text"
      placeholder={placeholder}
      className={className}
    />
  );
};

//Поле для ввода пароля
export const PswdInput = ({ placeholder, onChange, onFocus, className, name, value = '' }) => {
  const [isVisiblePswd, setIsVisiblePswd] = useState(false);
  return (
    <div className="authorisation-main-container_form-box_password-box">
      <ValidationInput
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        type={isVisiblePswd ? 'text' : 'password'}
        placeholder={placeholder}
        className={className}
        name={name}
      />
      <FlluppsSvg
        onClick={() => setIsVisiblePswd(!isVisiblePswd)}
        className='authorisation-main-container_form-box_password-box__pswd-icon'
        width={22}
        height={20}
        fill='none'
        active={isVisiblePswd}
        style={isVisiblePswd ? {} : { marginTop: '5px' }}
        type='eye'
      />
    </div>
  );
};

//Поля для ввода кода
export const CodeInput = ({ onChange, onFocus, className }) => {
  return (
    <div className="authorisation-main-container_form-box_password-box_code-box">
      <ValidationInput
        name="code-0"
        maxLength={1}
        type="number"
        onChange={onChange}
        className={className}
        onFocus={onFocus}
        pattern={"[0-9]"}
      />
      <ValidationInput
        name="code-1"
        maxLength={1}
        type="number"
        onChange={onChange}
        className={className}
        onFocus={onFocus}
        pattern={"[0-9]"}
      />
      <ValidationInput
        name="code-2"
        maxLength={1}
        type="number"
        onChange={onChange}
        className={className}
        onFocus={onFocus}
        pattern={"[0-9]"}
      />
      <ValidationInput
        name="code-3"
        maxLength={1}
        type="number"
        onChange={onChange}
        className={className}
        onFocus={onFocus}
        pattern={"[0-9]"}
      />
    </div>
  );
};

//Переадрицация на вход или регистрацию
export const LoginLink = ({ link, children, onClick }) => {
  return (
    <div className="authorisation-main-container_form-box_login-link-box">
      {children}
      <p onClick={onClick} className="authorisation-main-container_form-box_login-link-box__login">
        {link}
      </p>
    </div>
  );
};

//Кнопка выбора категории
export const ChooseBtn = ({ className, children, onClick, name }) => {
  return (
    <button className={className} onClick={onClick} name={name}>
      {children}
    </button>
  );
};
